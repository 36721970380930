<script setup lang="ts">
import { watch, reactive, computed } from 'vue'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import Token from '@/domains/Token'
import TokenAmount from '@/domains/TokenAmount'
import { useChainConnectionStore } from '@/pinia/chain-connection'

const props = withDefaults(
  defineProps<{
    token: Token
    walletAddress: string
    image?: boolean
    maxDecimals?: number
    minDecimals?: number
    locale?: string
    notation?: 'standard' | 'compact'
    compactDisplay?: 'short' | 'long'
    withValuation?: boolean
    invert?: boolean
  }>(),
  { image: false }
)

const chainConnection = useChainConnectionStore()
const fetcherState = reactive(createState({ response: new TokenAmount(props.token, '0') }))
const tokenBalanceFetcher =
  computed(() => {
    return new PromiseHandler(
      () => props.token.contract(chainConnection.identity).balanceOf(props.walletAddress),
      fetcherState
    )
  })

watch([() => props.walletAddress, () => props.token], () => {
  tokenBalanceFetcher.value.execute()
}, { immediate: true })
</script>

<template>
  <slot :execute="() => tokenBalanceFetcher.execute()" :state="fetcherState">
    <div class="inline-block" v-bind="$attrs">
      <template v-if="fetcherState.is('first-load')">
        <pp-skeleton-loader class="w-20" style="height: 1em;"></pp-skeleton-loader>
        <div>
          <pp-skeleton-loader v-if="withValuation" class="w-20 h-3 -translate-y-2"></pp-skeleton-loader>
        </div>
      </template>

      <pp-token-amount
        v-else-if="fetcherState.is('resolved-once')"
        :token-amount="(fetcherState.response as TokenAmount)"
        :image="image"
        :max-decimals="maxDecimals"
        :min-decimals="minDecimals"
        :locale="locale"
        :notation="notation"
        :compact-display="compactDisplay"
        :with-valuation="withValuation"
        :invert="invert"
      ></pp-token-amount>
      <pp-btn
        v-else-if="fetcherState.is('rejected')"
        variant="text"
        size="xs"
        color="red"
        @click="tokenBalanceFetcher.execute()"
      >Retry loading</pp-btn>
    </div>
  </slot>
</template>
