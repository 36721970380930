import MAINNET_ADDRESSES from 'sdk-mainnet/src/deployments/aurora_mainnet.json'
import TESTNET_ADDRESSES from 'sdk-testnet/src/deployments/aurora_testnet.json'

const isTestnet = import.meta.env.VITE_NETWORK_NAME?.toString().toLowerCase().includes('testnet')
const addresses = isTestnet ? TESTNET_ADDRESSES : MAINNET_ADDRESSES
addresses.supportedTokens = isTestnet ? addresses.test : {
  WNEAR: '0xc42c30ac6cc15fac9bd938618bcaa1a1fae8501d',
  USDC: '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
  USDT: '0x4988a896b1227218e4a686fde5eabdcabd91571f',
  DAI: '0xe3520349f477a5f6eb06107066048508498a291b',
  ETH: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  WBTC: '0xf4eb217ba2454613b15dbdea6e5f22276410e89e',
  PLY: '0x9ae13dd1ffd2008f68bc71d93c5a2381dc3f1d27'
}

export default {
  // AURORA: addresses.supportedTokens.AURORA.toLowerCase(),
  WNEAR: addresses.supportedTokens.WNEAR.toLowerCase(),
  USDC: addresses.supportedTokens.USDC.toLowerCase(),
  USDT: addresses.supportedTokens.USDT.toLowerCase(),
  DAI: addresses.supportedTokens.DAI.toLowerCase(),
  ETH: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  WBTC: addresses.supportedTokens.WBTC.toLowerCase(),
  PLY: addresses.supportedTokens.PLY?.toLowerCase(),
  // AU_AURORA: addresses.auTokens.AURORA.toLowerCase(),
  AU_WNEAR: addresses.auTokens.WNEAR.toLowerCase(),
  AU_USDC: addresses.auTokens.USDC.toLowerCase(),
  AU_USDT: addresses.auTokens.USDT.toLowerCase(),
  AU_DAI: addresses.auTokens.DAI.toLowerCase(),
  AU_ETH: addresses.auTokens.ETH.toLowerCase(),
  AU_WBTC: addresses.auTokens.WBTC.toLowerCase(),
  AU_PLY: addresses.auTokens.PLY?.toLowerCase()
}
