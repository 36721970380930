
<script setup lang="ts">
import MarketTable from '@/components/MarketTable.vue'
import { useGlobalStore } from '@/pinia/global'
import Valuation from '@/domains/Valuation'
import MoneyMarket from '@/domains/MoneyMarket'
import { computed } from 'vue'
import PromiseHandler from '@/domains/PromiseHandler'
import { MoneyMarketDetails } from '@/@types/MoneyMarketDetails'

const global = useGlobalStore()

const props = defineProps<{
  marketDetailsFetcher: PromiseHandler<MoneyMarketDetails[]>
}>()

const totalBorrowValuation = computed(() => {
  return (props.marketDetailsFetcher.state.response as MoneyMarket[])
    .map(market => market.details.response.totalTokenBorrowed.valuation)
    .reduce((acc, val) => acc.plus(val), new Valuation({ currency: 'USD', amount: 0 }))
})

const totalDepositValuation = computed(() => {
  return (props.marketDetailsFetcher.state.response as MoneyMarket[])
    .map(market => market.details.response.totalTokenDeposited.valuation)
    .reduce((acc, val) => acc.plus(val), new Valuation({ currency: 'USD', amount: 0 }))
}
)
</script>

<template>
  <div>
    <div class="flex items-center justify-center">
      <pp-card
        class="mt-16 xl:mt-0 md:flex-row bg-gradient-to-r md:from-transparent md:via-[rgba(229,232,235,0.15)] to-transparent"
      >
        <pp-card-body class="flex-1 order-2 -mt-2 md:mt-0 md:text-right md:order-1">
          <pp-stat :size="global.viewport.xlAndUp ? 'xl' : 'md'" header="Total Supplied">
            <template v-slot:value>
              <pp-valuation
                v-if="marketDetailsFetcher.state.is('resolved')"
                :valuation="totalDepositValuation"
              ></pp-valuation>
              <pp-skeleton-loader
                v-else-if="marketDetailsFetcher.state.is('loading')"
                class="w-32 h-8"
              ></pp-skeleton-loader>

              <div v-else-if="marketDetailsFetcher.state.is('rejected')">
                <pp-btn
                  color="red"
                  class="text-red-0"
                  @click="marketDetailsFetcher.execute()"
                >Retry fetching</pp-btn>
                <div class="mt-2 text-xs text-red-50">Data failed to load</div>
              </div>
            </template>
          </pp-stat>
        </pp-card-body>

        <img
          class="order-1 w-32 -mt-6 -ml-2 xl:w-44 md:ml-0 md:-mt-16 xl:-mt-20 md:order-2"
          src="@/assets/images/money-tree.svg"
          alt="Money tree bearing PLY token fruits"
        />

        <pp-card-body class="flex-1 order-3 -mt-2 md:mt-0">
          <pp-stat :size="global.viewport.xlAndUp ? 'xl' : 'md'" header="Total Borrowed">
            <template v-slot:value>
              <pp-valuation
                v-if="marketDetailsFetcher.state.is('resolved')"
                :valuation="totalBorrowValuation"
              ></pp-valuation>
              <pp-skeleton-loader
                v-else-if="marketDetailsFetcher.state.is('loading')"
                class="w-32 h-8"
              ></pp-skeleton-loader>

              <div v-else-if="marketDetailsFetcher.state.is('rejected')">
                <pp-btn
                  color="red"
                  class="text-red-0"
                  @click="marketDetailsFetcher.execute()"
                >Retry fetching</pp-btn>
                <div class="mt-2 text-xs text-red-50">Data failed to load</div>
              </div>
            </template>
          </pp-stat>
        </pp-card-body>
      </pp-card>
    </div>

    <div>
      <pp-alert
        v-if="marketDetailsFetcher.state.is('rejected')"
        class="mt-10 bg-gradient-to-r gradient-red"
        header="Unable to fetch data for the market table"
        content="Please wait for a few minutes and try again."
        variant="error"
        :actions="[{ text: 'Retry', action: () => marketDetailsFetcher.execute() }]"
      ></pp-alert>

      <div v-else class="overflow-x-auto">
        <MarketTable :fetch-state="marketDetailsFetcher.state" />
      </div>
    </div>
  </div>
</template>
