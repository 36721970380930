<script setup lang="ts">
import MoneyMarket from '@/domains/MoneyMarket'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import User from '@/domains/User'
import { computed, reactive } from 'vue'
import notifier from '@/domains/NotificationContainer'
import BN from 'bignumber.js'

const props = withDefaults(
  defineProps<{
    market: MoneyMarket
    user: User
    version?: 'turn-off' | 'disable'
  }>(),
  { version: 'disable' }
)

const emit = defineEmits<{
  (event: 'success'): void
}>()

const contract = computed(() => props.market.contract(props.user))
const before = computed(() => props.user.status)
const after = computed(() => contract.value.calcStatChanges(props.user.status, 'disableCollateral'))

const dangerThreshold = 0.90
const borrowUtilThreshold = 0.95

const isDangerous = computed(() => new BN(after.value.newBorrowUtilisation).gte(dangerThreshold))
const willOverBorrow = computed(() => new BN(after.value.newBorrowUtilisation).gte(borrowUtilThreshold))
const hasBorrowPosition = computed(() => props.user.status.marketPositions.findBy('market.assetToken.address', props.market.assetToken.address)?.borrowBalance.gt0())

const submitHandler = new PromiseHandler(
  () => contract.value.disableCollateral(),
  reactive(createState())
)
</script>

<template>
  <div>
    <form
      @submit.prevent="submitHandler.execute({
        success: () => {
          emit('success')
        },
        error: () => {
          notifier.error({ title: `Unable to disable ${market.assetToken.symbol} as collateral`, text: 'Please try again in a few minutes' })
        }
      })"
    >
      <pp-card>
        <pp-card-title class="mt-7">
          <h2 class="text-center">
            <span v-if="version === 'disable'">Disable collateral</span>
            <span v-else>Turn off active market</span>
          </h2>
          <p
            v-if="version === 'turn-off'"
            class="px-2 mt-5 text-base font-normal"
          >Please note that turning off an active market is equivalent to disabling the use of asset as collateral</p>
        </pp-card-title>

        <pp-card-body>
          <div class="text-center">
            <div class="inline-flex items-center text-4xl mt-7">
              <pp-token-img class="h-12" :token="market.assetToken"></pp-token-img>
              <pp-token-symbol class="ml-3" :token="market.assetToken"></pp-token-symbol>
            </div>
          </div>

          <div class="space-y-3 mt-7">
            <pp-left-right>
              <template #left>Borrow limit</template>
              <template #right>
                <pp-before-after
                  :before="before.borrowLimit.format()"
                  :after="after.newBorrowLimit.format()"
                ></pp-before-after>
              </template>
            </pp-left-right>

            <pp-left-right>
              <template #left>Borrow utilisation</template>
              <template #right>
                <pp-before-after
                  lower-better
                  :before="before.borrowUtilisation"
                  :after="after.newBorrowUtilisation"
                >
                  <template #before="{ value }">
                    <pp-percent :value="value"></pp-percent>
                  </template>
                  <template #after="{ value }">
                    <pp-percent v-if="isNaN(value)" class="text-gray-50" :value="0"></pp-percent>
                    <pp-percent
                      v-else
                      :class="value >= 0.9 ? 'text-red-500' : value >= 0.6 ? 'text-yellow-500' : 'text-green-500'"
                      :value="value"
                    ></pp-percent>
                  </template>
                </pp-before-after>
              </template>
            </pp-left-right>
          </div>

          <div v-if="willOverBorrow || isDangerous || hasBorrowPosition" class="py-5">
            <p
              v-if="hasBorrowPosition"
              class="text-yellow-500"
            >You cannot disable this collateral if you have a borrow position of the same asset.</p>
            <p v-else-if="willOverBorrow" class="text-red-500">
              Borrow utilisation more than
              <pp-percent :value="borrowUtilThreshold" :max-decimals="0" :min-decimals="0"></pp-percent>. Please reduce borrow amount or deposit more collaterals.
            </p>
            <p
              v-else-if="isDangerous"
              class="text-yellow-500"
            >Executing this transaction will significantly increase liquidation risk on your collaterals.</p>
          </div>
        </pp-card-body>

        <pp-card-actions>
          <pp-btn
            type="submit"
            class="w-full"
            size="lg"
            :disabled="willOverBorrow || hasBorrowPosition"
            :loading="submitHandler.state.is('loading')"
          >
            <span v-if="isDangerous && !(willOverBorrow || hasBorrowPosition)">Disable anyway</span>
            <span v-else>Disable now</span>
          </pp-btn>
        </pp-card-actions>
      </pp-card>
    </form>
  </div>
</template>
