<script setup lang="ts">
import MoneyMarket from '@/domains/MoneyMarket'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import User from '@/domains/User'
import { computed, reactive } from 'vue'
import notifier from '@/domains/NotificationContainer'

const props = defineProps<{
  market: MoneyMarket
  user: User
}>()

const emit = defineEmits<{
  (event: 'success'): void
}>()

const contract = computed(() => props.market.contract(props.user))
const before = computed(() => props.user.status)
const after = computed(() => contract.value.calcStatChanges(props.user.status, 'enableCollateral'))

const submitHandler = new PromiseHandler(
  () => contract.value.enableCollateral(),
  reactive(createState())
)

const positions = computed(() => props.user.status.marketPositions.where('isCollateral', true))
const isDisabled = computed(() => props.user.status.hasMaxedMarket && !positions.value.map(position => position.market.assetToken.address).includes(props.market.assetToken.address))
</script>

<template>
  <div>
    <form
      @submit.prevent="submitHandler.execute({
        success: () => {
          emit('success')
        },
        error: () => {
          notifier.error({ title: `Unable to enable ${market.assetToken.symbol} as collateral`, text: 'Please try again in a few minutes' })
        }
      })"
    >
      <pp-card>
        <pp-card-title class="mt-7">
          <h2 class="text-center">Enable collateral</h2>
        </pp-card-title>

        <pp-card-body>
          <div class="text-center">
            <div class="inline-flex items-center text-4xl mt-7">
              <pp-token-img class="h-12" :token="market.assetToken"></pp-token-img>
              <pp-token-symbol class="ml-3" :token="market.assetToken"></pp-token-symbol>
            </div>
          </div>

          <div class="space-y-3 mt-7">
            <pp-left-right>
              <template #left>Borrow limit</template>
              <template #right>
                <pp-before-after
                  :before="before.borrowLimit.format()"
                  :after="after.newBorrowLimit.format()"
                ></pp-before-after>
              </template>
            </pp-left-right>

            <pp-left-right>
              <template #left>Borrow utilisation</template>
              <template #right>
                <pp-before-after
                  lower-better
                  :before="before.borrowUtilisation"
                  :after="after.newBorrowUtilisation"
                >
                  <template #before="{ value }">
                    <pp-percent :value="value"></pp-percent>
                  </template>
                  <template #after="{ value }">
                    <pp-percent v-if="isNaN(value)" class="text-gray-50" :value="0"></pp-percent>
                    <pp-percent
                      v-else
                      :class="value >= 0.9 ? 'text-red-500' : value >= 0.6 ? 'text-yellow-500' : 'text-green-500'"
                      :value="value"
                    ></pp-percent>
                  </template>
                </pp-before-after>
              </template>
            </pp-left-right>
          </div>
        </pp-card-body>

        <pp-card-actions>
          <pp-btn
            type="submit"
            class="w-full"
            size="lg"
            :loading="submitHandler.state.is('loading')"
            :disabled="isDisabled"
          >
            <span>Enable now</span>
          </pp-btn>
        </pp-card-actions>
      </pp-card>
    </form>
  </div>
</template>
