import type { ButtonColor } from '@/@types/ButtonColor'
import { ButtonVariant } from '@/@types/ButtonVariant'

export const buttonColors = [
  'blue',
  'green',
  'brand',
  'red',
  'yellow'
] as const

const files = import.meta.globEager('./*.ts')

export default buttonColors.reduce((hash, color) => {
  // @ts-ignore
  hash[color] = Object.entries(files).find(([path, _module]) => {
    return path.replace(/^\.\//, '').replace(/\.\w+$/, '') === color
  })[1].default as Record<ButtonVariant, string>
  return hash
}, {} as Record<ButtonColor, Record<ButtonVariant, string>>)
