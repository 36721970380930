import { App, defineAsyncComponent } from 'vue'
import * as HeadlessUiComponents from '@headlessui/vue'

type ComponentMeta = { name: string; path: string, directory: string }

export function getGlobalComponents(): ComponentMeta[] {
  const requireComponent = import.meta.globEager('../components/global/*.vue')

  return Object.keys(requireComponent).map((fileName) => {
   //  @ts-ignore
    const componentName = fileName
      .split('/')
      .pop()
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
      .split('-')
      .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      .join('')

      const directory = fileName.split('/')
      directory.shift()
      directory.pop()

      return {
      name: componentName,
      directory: directory.join('/'),
      path: fileName.replace(/^\.\//, '')
    }
  })
  // case test with jest
}

export function registerGlobalComponent(app: App<Element>): void {
  const listGlobalComponent = getGlobalComponents()

  listGlobalComponent.forEach((component) => {
    app.component(
      component.name,
      defineAsyncComponent(() => import(/* @vite-ignore */ `../components/global/${component.name}.vue`))
      )
    }
  )

  Object.entries(HeadlessUiComponents).forEach(([componentName, component]) => {
    app.component(`Pp${componentName}`, component)
  })
}
