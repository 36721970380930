<script setup lang="ts">
import QueryCollection from '@/domains/QueryCollection'
import type { UserMarketDetails } from '@/@types/UserMarketDetails'
import { computed } from 'vue'
import StatusSectionItem from './StatusSectionItem.vue'

const props = defineProps<{
  walletAddress?: string
  marketPositions: QueryCollection<UserMarketDetails>
}>()

const positions = computed(() => props.marketPositions.where(position => position.isCollateral))
</script>

<template>
  <div v-if="positions.length === 0" class="px-2">
    <p class="text-gray-50">You have not entered any market yet.</p>
  </div>

  <pp-table v-else compact>
    <thead>
      <pp-tr>
        <pp-th valign="middle"></pp-th>
        <pp-th valign="middle" align="right">Status</pp-th>
        <pp-th valign="middle"></pp-th>
      </pp-tr>
    </thead>

    <tbody>
      <StatusSectionItem
        v-for="position in positions"
        :key="position.market.assetToken.address"
        :position="position"
      />
    </tbody>
  </pp-table>
</template>
