import { TabColor } from '@/@types/TabColor'
import { TabVariant } from '@/@types/TabVariant'

export const tabColors = [
  'blue',
  'green',
  'brand',
  'red',
  'yellow',
  'white'
] as const

type VariantStructure = {
  base: string
  active: Record<'primary' | 'secondary', string>
  inactive: string
}

const files = import.meta.globEager('./*.ts')

export default tabColors.reduce((hash, color) => {
  // @ts-ignore
  hash[color] = Object.entries(files).find(([path, _module]) => {
    return path.replace(/^\.\//, '').replace(/\.\w+$/, '') === color
  })[1].default as Record<TabVariant, VariantStructure>

  return hash
}, {} as Record<TabColor, Record<TabVariant, VariantStructure>>)
