import { defineStore } from 'pinia'
import { Subscriptions, API } from 'bnc-onboard/dist/src/interfaces'
import { ChainConnectionState } from '@/@types/ChainConnectionState'
import { ChainIdentity } from '@/@types/ChainIdentity'
import Onboard from 'bnc-onboard'
import { ethers } from 'ethers'
import switchNetwork from '@/helpers/switch-network'
import {
  ONBOARD_SELECTED_WALLET_KEY,
  ONBOARD_WALLET_CONFIGS,
  NETWORK_ID,
  BLOCKNATIVE_API_KEY,
  RPC_URL
} from '@/ui-config/defaults'
import User from '@/domains/User'
import { Ref, ref, markRaw } from 'vue'

const user: Ref<User | undefined> = ref()

/**
 * Initializes Onboard instance
 */
const initOnboard = (state: ChainConnectionState) => {
  const subscriptions: Subscriptions = {
    address: address => {
      state.walletAddress = address
    },
    network: chainId => {
      state.chainId = chainId
      state.provider = state.onboard?.getState().wallet.provider
      switchNetwork()
    },
    wallet: wallet => {
      state.provider = wallet.provider
      wallet.name
        ? localStorage.setItem(ONBOARD_SELECTED_WALLET_KEY, wallet.name)
        : localStorage.removeItem(ONBOARD_SELECTED_WALLET_KEY)
    },
    balance: () => {
      // noop
    }
  }

  const onboard = Onboard({
    dappId: BLOCKNATIVE_API_KEY,
    networkId: NETWORK_ID,
    subscriptions,
    darkMode: true,
    hideBranding: true,
    networkName: import.meta.env.VITE_NETWORK_NAME as string,
    walletSelect: { wallets: ONBOARD_WALLET_CONFIGS },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'accounts' },
      { checkName: 'network' },
      { checkName: 'connect' }
    ]
  })

  state.onboard = onboard

  const previouslySelectedWallet = localStorage.getItem(
    ONBOARD_SELECTED_WALLET_KEY
  )

  if (previouslySelectedWallet) {
    onboard.walletSelect(previouslySelectedWallet).then(selected => {
      if (selected) onboard.walletCheck()
    })
  }
  return onboard
}

export const useChainConnectionStore = defineStore('chain-connection', {
  state: () =>
    ({
      walletAddress: undefined,
      provider: undefined,
      chainId: undefined,
      onboard: undefined
    } as ChainConnectionState),
  getters: {
    identity (state): ChainIdentity {
      const provider = state.provider
      const web3Provider = provider ? new ethers.providers.Web3Provider(provider, 'any') : ethers.getDefaultProvider(RPC_URL)
      return {
        provider: markRaw(web3Provider),
        // @ts-ignore
        signer: markRaw(web3Provider.getSigner ? web3Provider.getSigner() : undefined)
      }
    },
    user(state): User | undefined {
      const provider = state.provider
      const web3Provider = provider ? new ethers.providers.Web3Provider(provider, 'any') : ethers.getDefaultProvider(RPC_URL)

      if (state.walletAddress) {
        const userConstructor = {
          provider: markRaw(web3Provider),
          // @ts-ignore
          signer: markRaw(web3Provider.getSigner?.()),
          address: state.walletAddress,
          details: user.value?.address === state.walletAddress ? user.value.details : undefined
        }

        user.value = new User(userConstructor)
        return user.value
      } else {
        user.value = undefined
        return undefined
      }
    },
    inCorrectChain (state) {
      return NETWORK_ID !== state.chainId
    },
    isConnected (state) {
      return !!state.walletAddress
    }
  },
  actions: {
    switchNetwork() {
      switchNetwork()
    },
    connectWallet () {
      this.loadedOnboard(onboard => {
        onboard.walletSelect().then(selected => {
          if (selected) onboard.walletCheck()
        })
      })
    },
    disconnectWallet () {
      this.loadedOnboard(onboard => {
        onboard.walletReset()
      })
    },
    /**
     * Only applicable for hardware wallet.
     * @TODO Fully implement this function
     */
    switchWallet () {
      this.loadedOnboard(onboard => {
        onboard.accountSelect()
      })
    },
    loadedOnboard (onSuccess: (onboard: API) => void) {
      if (!this.onboard) {
        this.init()
      }
      onSuccess(this.onboard as API)
    },
    init () {
      if (!this.onboard) {
        initOnboard(this)
      }
    }
  }
})
