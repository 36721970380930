<template>
  <span :title="valuation.strAmount">{{ formattedPrice }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Valuation from '@/domains/Valuation'

const props = withDefaults(
  defineProps<{
    locale?: string,
    maxDecimal?: 2,
    minDecimal?: 2,
    notation?: 'standard' | 'compact',
    valuation: Valuation
  }>(),
  {
    locale: 'en-US',
    maxDecimal: 2,
    minDecimal: 2,
    notation: 'standard'
  }
)

const formattedPrice = computed(() =>
  new Intl.NumberFormat(props.locale, {
    style: 'currency',
    currency: props.valuation.currency,
    maximumFractionDigits: props.maxDecimal,
    minimumFractionDigits: props.minDecimal,
    notation: props.notation
  }).format(props.valuation.amount as number)
)
</script>
