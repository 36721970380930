import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { APP_NAME, isTestnet } from '@/ui-config/defaults'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Market',
    component: Home,
    meta: {
      title: 'Market'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: 'Not Found'
    }
  }
]

const mainnetOnlyRoutes = {
  path: '/wallet',
  name: 'Wallet',
  component: () => import('../views/user/index.vue'),
  redirect: { name: 'Rewards' },
  children: [
    {
      path: 'rewards',
      name: 'Rewards',
      component: () => import('../views/user/Rewards.vue'),
      meta: {
        title: 'Rewards'
      }
    }
  ]
}

if (!isTestnet) {
  routes.push(mainnetOnlyRoutes)
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.afterEach(to => {
  const titleEl = document.querySelector('title')
  if (titleEl) {
    titleEl.innerText = `${APP_NAME} | ${to.meta.title}`
  }
})

export default router
