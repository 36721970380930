<script setup lang="ts">
import { useChainConnectionStore } from '@/pinia/chain-connection'

const chainConnection = useChainConnectionStore()
</script>

<template>
  <slot
    v-if="chainConnection.user"
    :user="chainConnection.user"
    :key="`${chainConnection.chainId}-${chainConnection.walletAddress}`"
  ></slot>

  <pp-card v-else>
    <pp-card-body class="space-y-6">
      <img
        src="@/assets/images/aurora_monk.jpg"
        alt="An origami monk standing on the hilltop. Behind the monk, an aurora can be seen stretching across the night sky. Aurigami birds can be seen flying in the distance."
      />

      <p class="text-xl">Earn in peace with Aurigami, the lending and borrowing haven on Aurora</p>

      <hr class="border-gray-400" />

      <pp-btn
        @click="chainConnection.connectWallet"
        size="lg"
        class="w-full bg-gradient-to-l hover:gradient-jade gradient-silk !bg-transparent fix-gradient"
      >Connect Wallet</pp-btn>
    </pp-card-body>
  </pp-card>
</template>
