import { PromiseState } from '@/@types/PromiseState'
import type { AdvancedPromiseState } from '@/@types/AdvancedPromiseState'
import type { PromiseMeta } from '@/@types/PromiseMeta'
import type { PromiseExecutionConfig } from '@/@types/PromiseExecutionConfig'
import NullPromise from './NullPromise'

const promiseStates: PromiseState[] = [
  'idle',
  'loading',
  'resolved',
  'rejected'
]

const defaultState: () => PromiseMeta<unknown> = () => {
  return {
    state: 'idle',
    nonce: 0,
    lastResolvedAt: null,
    lastRejectedAt: null,
    response: null,
    error: null,
    is(value) {
      const simpleConfig = promiseStates.reduce((config, key) => {
        config[key] = this.state === key
        return config
      }, {} as Record<PromiseState, boolean>)

      const advanceConfig: Record<AdvancedPromiseState, unknown> = {
        'first-load': this.state === 'loading' && !this.lastResolvedAt,
        'subsequent-load': this.state === 'loading' && this.lastResolvedAt,
        'resolved-once': this.lastResolvedAt
      }

      const config = { ...simpleConfig, ...advanceConfig }
      return !!config[value]
    }
  }
}

export function createState<T>(additionalVal: T = {} as T): PromiseMeta<unknown> & T {
  return { ...defaultState(), ...additionalVal }
}

export default class PromiseHandler<PromiseReturn> {
  private handler
  public state

  constructor(handler: () => Promise<PromiseReturn>, state = createState()) {
    this.handler = handler
    this.state = state
  }

  /**
   * `success` and `error` callback options will only run when the promise resolves with matching nonce.
   * Chaining `then`, `catch` or using the `await` syntax will run regardless whether the nonce matches.
   */
  execute<TransformedResponse>(
    options: PromiseExecutionConfig<PromiseReturn, TransformedResponse> = {}
  ): Promise<PromiseReturn> | NullPromise {
    const config = {
      force: false,
      // @ts-ignore
      transformResponse: (response: PromiseReturn) => response as TransformedResponse,
      success: () => {
        /* noop */
      },
      error: () => {
        /* noop */
      },
      timeout: undefined,
      log: false,
      ...options
    }

    if (config.log) {
      console.log('Executing promise')
    }

    if (!config.force && this.state.state === 'loading') {
      return new NullPromise()
    } else {
      this.state.state = 'loading'
      const executionNonce = ++this.state.nonce

      const timeoutableHandler: () => Promise<PromiseReturn> = () =>
        new Promise((resolve, reject) => {
          const executionPromise = this.handler()
          const timeout = config.timeout
            ? setTimeout(() => {
              throw new Error('Promise timed out')
            }, config.timeout)
            : undefined

          executionPromise
            .then((response) => {
              clearTimeout(timeout as NodeJS.Timeout)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })

      return new Promise((resolve, reject) => {
        setTimeout(
          () => {
            timeoutableHandler()
              .then((response) => {
                this._performIfValidNonce(executionNonce, () => {
                  const oldResponse = this.state.response
                  const transformedResponse = config.transformResponse(response)
                  this.state.response = transformedResponse
                  const cbValue = config.success(transformedResponse)
                  if (cbValue instanceof Promise) {
                    cbValue
                      .then(() => {
                        this.state.state = 'resolved'
                        this.state.lastResolvedAt = new Date()
                      })
                      .catch(error => {
                        this.state.response = oldResponse
                        throw new Error(error)
                      })
                  } else {
                    this.state.state = 'resolved'
                    this.state.lastResolvedAt = new Date()
                  }
                })
                resolve(response)
              })
              .catch((handlerError) => {
                this._performIfValidNonce(executionNonce, () => {
                  this.state.state = 'rejected'
                  this.state.lastRejectedAt = new Date()
                  this.state.error = handlerError
                  config.error(handlerError)
                })
                reject(handlerError)
              })
          },
          import.meta.env.MODE === 'development' ? 1500 : 0
        )
      })
    }
  }

  _performIfValidNonce(nonce: number, callback: () => void): void {
    if (nonce === this.state.nonce) {
      callback()
    }
  }
}
