import QueryCollection from '../QueryCollection'
import Token from '../Token'

export default class TokenCollection extends QueryCollection<Token> {
  static primary = 'address'

  static all (): TokenCollection {
    return TokenCollection.from(Token.tokens)
  }
}
