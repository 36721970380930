<template>
  <div class="inline-block">
    <div :class="sizeMap[size].header">
      <slot name="header">
        <h6 v-text="header" class="text-gray-300"></h6>
      </slot>
    </div>

    <div :class="sizeMap[size].gap"></div>

    <div :class="sizeMap[size].value">
      <slot name="value">
        <div v-text="value"></div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GenericComponentSize } from '@/@types/GenericComponentSize'

withDefaults(

  defineProps<{
    header?: string,
    value?: any,
    size?: GenericComponentSize
  }>(),
  { size: 'md' }
)
const sizeMap = {
  xs: {
    header: 'text-xs',
    value: 'text-sm',
    gap: ''
  },
  sm: {
    header: 'text-sm',
    value: 'text-xl',
    gap: ''
  },
  md: {
    header: 'text-base',
    value: 'text-2xl',
    gap: 'mt-1'
  },
  lg: {
    header: 'text-base',
    value: 'text-3xl',
    gap: 'mt-1'
  },
  xl: {
    header: 'text-xl',
    value: 'text-4xl',
    gap: 'mt-3'
  }
}
</script>
