<script setup lang="ts">
import TheNavbar from './components/navbar/TheNavbar.vue'
import ToastContainer from '@/components/toast/ToastContainer.vue'
import MyAccountLayout from '@/components/MyAccountLayout.vue'
import { useChainConnectionStore } from './pinia/chain-connection'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import MoneyMarket from '@/domains/MoneyMarket'
import { ref, watch } from 'vue'
import { isTestnet } from './ui-config/defaults'

const markets = ref(MoneyMarket.query().where('inactive', false).toArray())

const chainConnection = useChainConnectionStore()
watch(() => chainConnection.chainId, () => {
  marketDetailsFetcher.value.execute()
})

const marketDetailsFetcher = ref(new PromiseHandler(
  () => Promise.all(
    markets.value.map(market => market
      .contract(chainConnection.identity)
      .getDetails()
    )
  ),
  createState()
))

const injectMarkets = () => {
  if (marketDetailsFetcher.value.state.state === 'resolved' && chainConnection.user) {
    chainConnection.user.details.response.marketPositions.forEach(position => {
      // @ts-ignore
      const activeMarket = marketDetailsFetcher.value.state.response.find(market => market.assetToken.address === position.market.assetToken.address)

      if (activeMarket) {
        position.market = activeMarket
      }
    })
  }
}
watch(() => marketDetailsFetcher.value.state.state, injectMarkets, { immediate: true })
watch(() => chainConnection.user, injectMarkets, { immediate: true, deep: true })
marketDetailsFetcher.value.execute()
</script>

<template>
  <div
    v-if="isTestnet"
    class="container relative flex items-center justify-center min-h-screen px-4 mx-auto"
  >
    <pp-brandmark class="w-64 opacity-30"></pp-brandmark>

    <h1 class="absolute text-4xl font-bold text-center sm:text-5xl">
      Testnet is over.
      <a class="block underline" href="https://aurigami.finance">Checkout mainnet!</a>
    </h1>
  </div>

  <template v-else>
    <TheNavbar class="fixed top-0 z-10 w-full h-16 bg-cyan-900" />

    <div class="container mt-16 min-h-[calc(100vh-4rem)] mx-auto px-3 md:px-4 py-6">
      <transition
        enter-active-class="transition ease-out origin-top transform"
        enter-from-class="scale-0 opacity-0"
        leave-active-class="transition ease-out origin-top transform"
        leave-to-class="scale-0 opacity-0"
      >
        <pp-alert
          variant="error"
          v-if="chainConnection.isConnected && chainConnection.inCorrectChain"
          header="You are on the wrong network"
          content="Please change your network before continuing"
          :actions="[{ text: 'Switch Network', action: chainConnection.switchNetwork }]"
        ></pp-alert>
      </transition>
      <router-view
        v-slot="{ Component }"
        :market-details-fetcher="marketDetailsFetcher"
        :key="`${chainConnection?.user?.address}-${chainConnection.chainId}`"
      >
        <my-account-layout
          v-if="['Market', 'Rewards'].includes($route.name as string)"
          :market-details-fetcher="marketDetailsFetcher"
        >
          <pp-page-transition>
            <component :is="Component" />
          </pp-page-transition>
        </my-account-layout>

        <pp-page-transition v-else>
          <component :is="Component" />
        </pp-page-transition>
      </router-view>
    </div>

    <ToastContainer class="z-10" />
  </template>
</template>
