<template>
  <pp-tab-group @change="emit('update:modelValue', $event)" :default-index="modelValue">
    <pp-tab-list v-bind="$attrs" class="flex p-1 space-x-1 rounded">
      <pp-tab
        v-for="(tab, index) in tabs"
        as="template"
        :key="typeof tab === 'string' ? tab : tab.text"
        v-slot="{ selected }"
      >
        <pp-activable
          :active="selected"
          color="white"
          :data-id="indexedId(index)"
          class="flex-1 sm:text-xl !ring-0 focus:bg-gray-50/10 overflow-hidden"
          :class="activableClass"
        >
          <div class="w-full">
            <div>
              <slot name="tab-item" :tab="tab">{{ typeof tab === 'string' ? tab : tab.text }}</slot>
            </div>
            <div class="mt-2">
              <div class="h-1 overflow-hidden">
                <transition
                  appear
                  enter-from-class="scale-y-0 -translate-x-full opacity-0"
                  leave-to-class="scale-y-0 translate-x-full opacity-0"
                >
                  <div
                    v-if="selected"
                    style="clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 70%);"
                    class="w-full h-1 -ml-2 transition duration-300 ease-out bg-gradient-to-r from-white/20 to-red-400/50"
                  ></div>
                </transition>
              </div>

              <div class="h-5 -mt-4" aria-hidden="true">
                <transition
                  appear
                  enter-from-class="scale-0 rotate-90 -translate-x-full opacity-0"
                  leave-to-class="scale-y-0 rotate-90 translate-x-full opacity-0"
                >
                  <div
                    v-if="selected"
                    class="text-right transition duration-300 ease-out origin-right"
                  >
                    <img :src="Bird" alt="Origami bird" class="inline-block h-4" />
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </pp-activable>
      </pp-tab>
    </pp-tab-list>

    <TabPanelsSlot />
  </pp-tab-group>
</template>

<script setup lang="tsx">
import { getCurrentInstance, watch, useSlots, defineComponent, Fragment, VNodeArrayChildren } from 'vue'
import { TabPanels, TabPanel } from '@headlessui/vue'
import Bird from '@/assets/images/bird.svg'
import type { TabItem } from '@/@types/TabItem'

const instance = getCurrentInstance()

const props = withDefaults(
  defineProps<{ tabs: TabItem[] | string[], modelValue?: number, activableClass?: string }>(),
  { modelValue: 0, activableClass: '' }
)

const slots = useSlots()

const indexedId = (index: number) => `pp-tab-${instance?.uid}-${index}`

const handleValueChange = (newIndex: number) => {
  const tabBtn = document.querySelector(`[data-id="${indexedId(newIndex)}"]`) as HTMLElement | null

  if (tabBtn) {
    if (tabBtn.getAttribute('aria-selected') !== 'true') {
      const activeEl = document.activeElement as HTMLElement | null
      tabBtn.click()
      activeEl?.focus()
    }
  }
}

const emit = defineEmits<{
  (event: 'update:modelValue', payload: number): void
}>()

watch(() => props.modelValue, (newVal) => {
  handleValueChange(newVal)
})

const TabPanelsSlot = defineComponent({
  render() {
    const content = slots.default?.()
    const renderedContent = (content?.[0].type === Fragment ? content[0].children : content) || []
    return <TabPanels class="mt-2">
      {
        typeof renderedContent === 'string'
          ? renderedContent
          : (renderedContent as VNodeArrayChildren).map((content, index) =>
            <TabPanel key={index} class="p-2 rounded focus:outline-none focus:ring-2 ring-brand-500 ring-opacity-60">
              {content}
            </TabPanel>
          )
      }
    </TabPanels>
  }
})
</script>

<script lang="tsx">
export default {
  inheritAttrs: false
}
</script>
