/* eslint-disable */
export default class QueryCollection<Queryable> extends Array<Queryable> {
  static primaryKey = 'id'

  static from<T>(array: T[]) {
    return new this(...array)
  }

  _whereFilter() {
    const defaultHandler = (queryableValue: any) => {
      const key: string = arguments[0]
      const nestedValue = key
        .split('.')
        .reduce((value, nestedKey) => value?.[nestedKey], queryableValue)
      const matcher = arguments[1]

      return !!(typeof matcher === 'function'
        ? matcher(nestedValue)
        : []
          .concat(matcher)
          .some((value) => ([].concat(matcher) as any[]).includes(nestedValue)))
    }

    const handler = {
      1: arguments[0],
      2: defaultHandler
    }

    // @ts-ignore
    return (handler[arguments.length] as () => boolean) || defaultHandler
  }

  all() {
    return this
  }

  where(key: string, val: (value: any) => any): this
  where(key: string, val: any): this
  where(filter: (item: Queryable) => any): this
  where() {
    return this.filter((queryable) =>
      // @ts-ignore
      this._whereFilter(...arguments)(queryable)
    )
  }

  whereNot(key: string, val: (value: any) => any): this
  whereNot(key: string, val: any): this
  whereNot(filter: (item: Queryable) => any): this
  whereNot() {
    return this.filter(
      // @ts-ignore
      (queryable) => !this._whereFilter(...arguments)(queryable)
    )
  }

  findBy(key: string, val: (value: any) => any): Queryable | undefined
  findBy(key: string, val: any): Queryable | undefined
  findBy(filter: (item: Queryable) => any): Queryable | undefined
  findBy() {
    // @ts-ignore
    return this.where(...arguments)[0]
  }

  mustFindBy(key: string, val: (value: any) => any): Queryable
  mustFindBy(key: string, val: any): Queryable
  mustFindBy(filter: (item: Queryable) => any): Queryable
  mustFindBy() {
    // @ts-ignore
    const item = this.findBy(...arguments)
    if (item) return item
    throw new Error(`Unable to find ${this.constructor.name}, arguments: ${[...arguments]}`)
  }

  findByPrimaryKey(id: string) {
    const record = this.findBy(QueryCollection.primaryKey, id)

    if (record) return record
    throw new Error(
      `Unable to find record of {${QueryCollection.primaryKey}: ${id}}`
    )
  }

  first(): Queryable | undefined
  first(n: number): this
  first(n: number | undefined = undefined) {
    return n === undefined
      ? (this[0] as Queryable | undefined)
      : this.slice().splice(0, n)
  }

  last(): Queryable | undefined
  last(n: number): this
  last(n: number | undefined = undefined) {
    return n === undefined
      ? (this.slice().reverse()[0] as Queryable | undefined)
      : this.slice().reverse().splice(0, n).reverse()
  }

  toArray() {
    return [...this]
  }
}
