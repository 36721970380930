<template>
  <nav class="flex items-center p-4 space-x-3 shadow">
    <router-link to="/" class="pp-activable focus:ring-brand-500">
      <pp-lockup class="h-8"></pp-lockup>
    </router-link>

    <div class="flex-grow"></div>

    <div class="flex items-center space-x-2">
      <FaucetBtn size="sm" />

      <pp-btn
        @click="() => chainConnection.isConnected ? chainConnection.disconnectWallet() : chainConnection.connectWallet()"
        class="bg-gradient-to-l hover:gradient-jade gradient-silk !bg-transparent fix-gradient"
        size="sm"
      >
        <pp-wallet-address
          v-if="chainConnection.isConnected"
          class="lowercase"
          :address="(chainConnection.walletAddress as string)"
        ></pp-wallet-address>
        <span v-else>Connect Wallet</span>
      </pp-btn>

      <pp-dropdown icon :options="externalLinkMetadatas" variant="highlight" color="white">
        <template v-slot:activator-content>
          <DotsHorizontalIcon />
        </template>

        <template
          v-slot:options-container="{ optionClass, optionInactiveClass, optionActiveClass }"
        >
          <div class="py-1">
            <div>
              <pp-menu-item v-for="link in internalLinks" :key="link.text" v-slot="{ active }">
                <pp-nav-link
                  :to="link.to"
                  color="white"
                  class="justify-start"
                  :class="[optionClass, active ? optionActiveClass : optionInactiveClass]"
                >
                  <template v-slot="navlink">
                    <ChevronRightIcon v-if="navlink.active" class="w-5 h-5" />

                    <div class="ml-2">
                      <span class="align-middle">{{ link.text }}</span>
                    </div>
                  </template>
                </pp-nav-link>
              </pp-menu-item>

              <pp-menu-item key="static" v-slot="{ active }">
                <pp-nav-link
                  external="https://rainbowbridge.app/transfer"
                  color="white"
                  class="justify-start"
                  :class="[optionClass, active ? optionActiveClass : optionInactiveClass]"
                >
                  <RainbowBridge class="w-5" />

                  <div class="ml-2">
                    <span class="align-middle">Bridge</span>
                  </div>
                </pp-nav-link>
              </pp-menu-item>
            </div>

            <hr class="my-2 opacity-20" />

            <div>
              <pp-menu-item
                v-for="link in externalLinks"
                :key="link.text"
                v-slot="{ active }"
                as="a"
                target="_blank"
                :href="link.href"
              >
                <div
                  class="rounded"
                  :class="[optionClass, active ? optionActiveClass : optionInactiveClass]"
                >
                  <div>
                    <component :is="link.icon" class="w-5 h-5"></component>
                  </div>

                  <div class="ml-3">
                    <span class="align-middle">{{ link.text }}</span>
                  </div>
                </div>
              </pp-menu-item>
            </div>
          </div>
        </template>
      </pp-dropdown>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { DotsHorizontalIcon, ChevronRightIcon } from '@heroicons/vue/outline'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import FaucetBtn from '../FaucetBtn.vue'
import RainbowBridge from '@/components/svg/solid/RainbowBridge.vue'

type InternalLink = {
  text: string,
  to: RouteLocationRaw
}

type ExternalLink = {
  text: string,
  href: string,
  icon?: unknown
}

const props = defineProps<{
  internalLinks: Array<InternalLink>
  externalLinks: Array<ExternalLink>
}>()

const chainConnection = useChainConnectionStore()

const externalLinkMetadatas = computed(() => props.externalLinks.map(link => ({ ...link, as: 'a', attrs: { href: link.href, target: '_blank' } })))
</script>
