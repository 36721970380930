<template>
  <span class="pp-wallet-address" v-text="formattedAddress"></span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
const props = withDefaults(
  defineProps<{
    address: string,
    front?: number,
    back?: number
  }>()
  , {
    front: 5,
    back: 4
  }
)

const formattedAddress = computed(() => `${props.address.slice(0, props.front)}...${props.address.slice(props.address.length - props.back)}`)
</script>

<style>
.pp-wallet-address {
  font-variant-ligatures: no-contextual;
}
</style>
