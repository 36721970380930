export default {
  fill: 'text-white bg-brand-600 hover:bg-brand-700 focus:ring-brand-500 disabled:text-gray-400 disabled:bg-gray-200',
  text: 'text-brand-800 shadow-none hover:bg-brand-100 focus:ring-brand-500 disabled:text-gray-400 disabled:bg-transparent',
  outline:
    'border border-brand-800 text-brand-800 hover:bg-brand-100 focus:ring-brand-500 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent',
  gradient:
    'bg-gradient-to-r fix-gradient gradient-aqua text-white hover:gradient-jade group-hover:gradient-jade focus:ring-brand-500 disabled:!from-gray-200/20 disabled:!to-transparent disabled:text-gray-400',
  accent:
    'text-brand-700 bg-brand-100 hover:bg-brand-200 focus:ring-brand-500 disabled:bg-gray-200 disabled:text-gray-400'
}
