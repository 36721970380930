import BigNumberjs, { BigNumber } from 'bignumber.js'
import Token from '@/domains/Token'
import Valuation from '@/domains/Valuation'
import { TokenAmount as SdkTokenAmount } from 'sdk-mainnet/src/tokenAmount'
import type { TokenAmount as SdkTokenAmountType } from 'sdk-mainnet/dist/tokenAmount'

const decimalFactor = (decimalPlace: number): string =>
  new BigNumberjs(10).pow(new BigNumberjs(decimalPlace)).toString()

export default class TokenAmount {
  public readonly token
  private rawAmnt: string

  public constructor(token: Token, amount: string | number, isRaw = true) {
    this.rawAmnt = isRaw
      ? new BigNumberjs(amount).toFixed(0)
      : new BigNumberjs(amount).times(decimalFactor(token.decimals)).toFixed(0)
    this.token = token
  }

  static from(sdkTokenAmount: SdkTokenAmount) {
    return new this(
      Token.query().mustFindBy('address', sdkTokenAmount.token.address),
      sdkTokenAmount.rawAmount(),
      true
    )
  }

  get formattedAmount() {
    return new BigNumberjs(this.rawAmnt)
      .div(decimalFactor(this.token.decimals))
      .toFixed()
  }

  get rawAmount() {
    return this.rawAmnt
  }

  get valuation() {
    return new Valuation({
      currency: 'USD',
      amount: new BigNumber(this.token.readValuation.amount)
        .multipliedBy(
          new BigNumber(this.formattedAmount)
        ).toString()
    })
  }

  plus(tokenAmount: TokenAmount) {
    if (this.token.address !== tokenAmount.token.address) throw new Error('Cannot perform arithmetic across different token.address')

    return new TokenAmount(
      tokenAmount.token,
      new BigNumber(this.rawAmount)
        .plus(new BigNumber(tokenAmount.rawAmount))
        .toFixed(),
      true
    )
  }

  minus(tokenAmount: TokenAmount) {
    if (this.token.address !== tokenAmount.token.address) throw new Error('Cannot perform arithmetic across different token.address')

    return new TokenAmount(
      tokenAmount.token,
      new BigNumber(this.rawAmount)
        .minus(new BigNumber(tokenAmount.rawAmount))
        .toFixed(),
      true
    )
  }

  div(value: TokenAmount): string
  div(value: number | string): TokenAmount
  div(value: TokenAmount | number | string) {
    if (typeof value === 'string' || typeof value === 'number') {
      return new TokenAmount(
        this.token,
        new BigNumber(this.rawAmount).div(
          new BigNumber(value)
        ).toFixed(),
        true
      )
    } else {
      if (this.token.address !== value.token.address) throw new Error('Cannot perform arithmetic across different token.address')

      return new BigNumber(this.rawAmount)
        .div(new BigNumber(value.rawAmount))
        .toFixed()
    }
  }

  mul(multiplier: number | string) {
    const amount = new BigNumber(this.rawAmount)
      .multipliedBy(new BigNumber(multiplier))
      .toFixed()

    return new TokenAmount(this.token, amount, true)
  }

  gt(tokenAmount: TokenAmount) {
    return new BigNumberjs(this.rawAmount).gt(new BigNumberjs(tokenAmount.rawAmount))
  }

  lt(tokenAmount: TokenAmount) {
    return new BigNumberjs(this.rawAmount).lt(new BigNumberjs(tokenAmount.rawAmount))
  }

  gte(tokenAmount: TokenAmount) {
    return new BigNumberjs(this.rawAmount).gte(new BigNumberjs(tokenAmount.rawAmount))
  }

  lte(tokenAmount: TokenAmount) {
    return new BigNumberjs(this.rawAmount).lte(new BigNumberjs(tokenAmount.rawAmount))
  }

  eq(tokenAmount: TokenAmount) {
    return new BigNumberjs(this.rawAmount).eq(new BigNumberjs(tokenAmount.rawAmount))
  }

  gt0() {
    return new BigNumberjs(this.rawAmount).gt(0)
  }

  lt0() {
    return new BigNumberjs(this.rawAmount).lt(0)
  }

  gte0() {
    return new BigNumberjs(this.rawAmount).gte(0)
  }

  lte0() {
    return new BigNumberjs(this.rawAmount).lte(0)
  }

  toSdk() {
    // @ts-ignore
    return new SdkTokenAmount(this.token, this.rawAmount, true) as SdkTokenAmountType
  }
}
