<script setup lang="ts">
import { getCurrentInstance } from 'vue'
import type { InputElementEvent } from '@/@types/InputElementEvent'

const instance = getCurrentInstance()
const id = `pp-checkbox-${instance?.uid}`

withDefaults(
  defineProps<{ modelValue?: boolean, label?: string, name?: string }>(),
  { modelValue: false }
)

const emit = defineEmits<{
  (event: 'update:modelValue', checked: boolean): void
}>()
const handleChange = (event: InputElementEvent) => {
  emit('update:modelValue', event.target.checked)
}

</script>

<template>
  <div class="inline-flex items-start">
    <div class="flex items-center h-5">
      <input
        :checked="modelValue"
        @change="handleChange($event as InputElementEvent)"
        :id="id"
        :name="name"
        type="checkbox"
        class="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded transition"
      />
    </div>

    <div v-if="label" class="ml-3 text-sm">
      <label :for="id" class="font-medium text-gray-700">{{ label }}</label>
    </div>
  </div>
</template>
