import QueryCollection from '../QueryCollection'
import MoneyMarket from '@/domains/MoneyMarket'
import MoneyMarkets from '@/app-config/money-market'

export default class MoneyMarketCollection extends QueryCollection<MoneyMarket> {
  static primary = 'address'

  static all (): MoneyMarketCollection {
    return this.from(MoneyMarkets.map(moneyMarket => new MoneyMarket(moneyMarket)))
  }
}
