import { defineStore } from 'pinia'

const getViewportWidth = () =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

export const useGlobalStore = defineStore('global', {
  state: () => ({
    time: new Date(),
    width: getViewportWidth()
  }),
  actions: {
    init () {
      setInterval(() => {
        this.time = new Date()
      }, 1000)

      window.addEventListener('resize', () => {
        this.width = getViewportWidth()
      })
    }
  },
  getters: {
    viewport (state) {
      const width = state.width
      const sm = 640
      const md = 768
      const lg = 1024
      const xl = 1280
      const xl2 = 1536

      return {
        xs: width < sm,
        sm: width >= sm && width < md,
        md: width >= md && width < lg,
        lg: width >= lg && width < xl,
        xl: width >= xl && width < xl2,
        '2xl': width >= xl2,
        smAndUp: width >= sm,
        mdAndUp: width >= md,
        lgAndUp: width >= lg,
        xlAndUp: width >= xl,
        '2xlAndUp': width >= xl2,
        xsAndDown: width < sm,
        smAndDown: width < md,
        mdAndDown: width < lg,
        lgAndDown: width < xl,
        xlAndDown: width < xl2
      }
    }
  }
})
