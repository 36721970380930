export default {
  fill: 'text-white bg-green-600 hover:bg-green-700 focus:ring-green-500 disabled:text-gray-400 disabled:bg-gray-200',
  text: 'text-green-800 shadow-none hover:bg-green-100 focus:ring-green-500 disabled:text-gray-400 disabled:bg-transparent',
  outline:
    'border border-green-800 text-green-800 hover:bg-green-100 focus:ring-green-500 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent',
  gradient:
    'bg-gradient-to-r fix-gradient from-green-500 via-green-700 to-green-500 bg-pos-0 bg-size-200 text-white hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-green-500 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 disabled:text-gray-400',
  accent:
    'text-green-700 bg-green-100 hover:bg-green-200 focus:ring-green-500 disabled:bg-gray-200 disabled:text-gray-400'
}
