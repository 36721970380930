export default {
  fill: {
    base:
      'rounded-md focus:ring-green-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'bg-green-600 text-white',
      secondary: 'text-green-100'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  highlight: {
    base: 'rounded-md focus:ring-green-500 disabled:text-gray-400',
    active: {
      primary: 'bg-green-100 text-green-700',
      secondary: 'text-green-700'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  bold: {
    base: 'focus:ring-green-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'text-green-600',
      secondary: 'text-green-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  outline: {
    base:
      'text-green-700 focus:ring-green-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'font-semibold border-2 border-green-500 focus:ring-green-200',
      secondary: 'text-green-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  }
}
