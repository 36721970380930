<script setup lang="ts">
import { computed, reactive, watch, defineComponent } from 'vue'
import TokenAmount from '@/domains/TokenAmount'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import BigNumberjs from 'bignumber.js'
import notifier from '@/domains/NotificationContainer'

const props = defineProps<{
  tokenAmount: TokenAmount
  spenderAddress: string
}>()

const uint256limit = new BigNumberjs(2)
  .pow(new BigNumberjs(256))
  .minus(new BigNumberjs(1))
  .toFixed()

const chainConnection = useChainConnectionStore()
const tokenContract = computed(() => props.tokenAmount.token.contract(chainConnection.identity))

const allowance = reactive(createState({ response: new TokenAmount(props.tokenAmount.token, '0') }))
const allowanceHandler = computed(() => {
  return () => tokenContract.value.allowance(chainConnection.walletAddress as string, props.spenderAddress)
})
const allowanceFetcher = computed(() => new PromiseHandler(allowanceHandler.value, allowance))

const approve = reactive(createState({ response: false }))
const approveHandler = computed(() => new PromiseHandler(
  () => tokenContract.value.approve(
    props.spenderAddress,
    new TokenAmount(props.tokenAmount.token, uint256limit, true)
  ),
  approve
))

const isApproved = computed(() => {
  return allowance.response.gte(props.tokenAmount)
})
const emit = defineEmits<{
  (event: 'update:approval', approval: boolean): void
}>()

watch(() => [chainConnection.walletAddress], () => {
  allowanceFetcher.value.execute({ force: true })
}, { immediate: true })

watch(isApproved, (newVal) => {
  emit('update:approval', newVal)
}, { immediate: true })
</script>

<script lang="ts">
export default defineComponent({
  inheritAttrs: false
})
</script>

<template>
  <slot v-if="isApproved"></slot>
  <pp-btn
    v-else
    v-bind="$attrs"
    class="flex-1"
    @click="approveHandler.execute({
      success: () => {
        allowanceFetcher.execute({ force: true })
        notifier.success({ title: `Successfully approved ${tokenAmount.token.symbol}`, text: '' })
      },
      error: () => {
        notifier.error({ title: `Unable to approve ${tokenAmount.token.symbol}`, text: 'Please try again in a few minutes' })
      }
    })"
    :loading="approve.is('loading') || allowance.is('loading')"
    :disabled="isApproved"
  >Approve {{ tokenAmount.token.symbol }}</pp-btn>
</template>
