<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import TokenAmount from '@/domains/TokenAmount'
import BigNumberjs from 'bignumber.js'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import type { GenericComponentSize } from '@/@types/GenericComponentSize'

type SpendingMeta = { approved: boolean, spending: TokenAmount }

const chainConnection = useChainConnectionStore()

const props =
  withDefaults(
    defineProps<{
      spendings?: TokenAmount | TokenAmount[],
      size?: GenericComponentSize,
      spenderAddress: string,
      direction?: 'vertical' | 'horizontal'
    }>(),
    {
      spendings: () => [],
      size: 'md',
      direction: 'vertical'
    }
  )

const spendingAmounts = computed(() => {
  const spendings = [props.spendings].flat()

  return Object.values(
    spendings.reduce((hash, spending) => {
      if (hash[spending.token.address]) {
        const trackedAmount = new BigNumberjs(hash[spending.token.address].rawAmount)
        const newAmount = new BigNumberjs(spending.rawAmount)
        hash[spending.token.address] = new TokenAmount(spending.token, trackedAmount.plus(newAmount).toString(), true)
      } else {
        hash[spending.token.address] = spending
      }

      return hash
    }, {} as { [address: string]: TokenAmount })
  )
})

const spendingAmountsMeta = ref<SpendingMeta[]>([])

watch(spendingAmounts, (newValue) => {
  spendingAmountsMeta.value = newValue.map(spending => ({ spending, approved: false }))
})

const allApproved = computed(() => spendingAmountsMeta.value.every(amountMeta => amountMeta.approved))
</script>

<template>
  <div>
    <div v-if="chainConnection.isConnected">
      <slot v-if="allApproved"></slot>

      <div v-else class="flex" :class="direction ? 'flex-col space-y-4' : 'space-x-2'">
        <pp-async-token-approve-btn
          v-for="amountMeta in spendingAmountsMeta"
          :key="[amountMeta.spending.token.address, spenderAddress].join('-')"
          :token-amount="(amountMeta.spending as TokenAmount)"
          :spender-address="spenderAddress"
          v-model:approval="amountMeta.approved"
          class="flex w-full"
          size="lg"
        ></pp-async-token-approve-btn>
      </div>
    </div>

    <pp-btn v-else class="flex w-full" :size="size">Connect Wallet</pp-btn>
  </div>

  <pp-relative-time format="countdown" :significant-units="1"></pp-relative-time>
</template>
