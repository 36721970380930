import { ref, watch, Ref } from 'vue'
import type {
  NotificationType,
  NotificationStatus,
  NotificationBase,
  AppNotification
} from '@/@types/Notification'
const localStorageKey = 'notifications-cache'

const readCache = (): AppNotification[] => {
  const cache = localStorage.getItem(localStorageKey)
  return typeof cache === 'string' ? JSON.parse(cache) || [] : []
}

const setCache = (data: AppNotification[]) => {
  localStorage.setItem(localStorageKey, JSON.stringify(data.slice(0, 20)))
}

export class NotificationContainer {
  public notifications: Ref<AppNotification[]>

  constructor() {
    this.notifications = ref(readCache())
  }

  success({ title, text, action, status = 'completed' }: NotificationBase & { status?: NotificationStatus }) {
    return this._addNotification({
      title,
      text,
      action,
      status,
      type: 'success'
    })
  }

  error({ title, text, action, status = 'completed' }: NotificationBase & { status?: NotificationStatus }) {
    return this._addNotification({
      title,
      text,
      action,
      status,
      type: 'error'
    })
  }

  info({ title, text, action, status = 'completed' }: NotificationBase & { status?: NotificationStatus }) {
    return this._addNotification({
      title,
      text,
      action,
      status,
      type: 'info'
    })
  }

  clear(id: string) {
    this.notifications.value = this.notifications.value.filter(
      (notification) => notification.id !== id
    )
  }

  clearAll() {
    this.notifications.value = []
  }

  _addNotification({
    title,
    text,
    action,
    type = 'info',
    status = 'completed'
  }: NotificationBase & { type: NotificationType, status?: NotificationStatus }) {
    const newNotification = {
      title,
      text,
      action,
      type,
      status,
      id: new Date().getTime().toString() + Math.random(),
      createdAt: new Date().toISOString()
    }
    this.notifications.value = [
      newNotification,
      ...this.notifications.value
    ]

    return newNotification
  }

  _updateCache() {
    setCache(this.notifications.value)
  }
}

const notificationContainer = new NotificationContainer()

watch(notificationContainer.notifications, () => {
  notificationContainer._updateCache()
})

export default notificationContainer
