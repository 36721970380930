<script setup lang="ts">
import { computed } from 'vue'
import { useGlobalStore } from '@/pinia/global'
import type { TimeMap } from '@/@types/TimeMap'

const SECOND: TimeMap = { duration: 1000, unit: 'second', shortUnit: 's' }
const MINUTE: TimeMap = { duration: 60 * SECOND.duration, unit: 'minute', shortUnit: 'm' }
const HOUR: TimeMap = { duration: 60 * MINUTE.duration, unit: 'hour', shortUnit: 'h' }
const DAY: TimeMap = { duration: 24 * HOUR.duration, unit: 'day', shortUnit: 'd' }
const WEEK: TimeMap = { duration: 7 * DAY.duration, unit: 'week', shortUnit: 'w' }
const MONTH: TimeMap = { duration: 30 * DAY.duration, unit: 'month', shortUnit: 'mo' }
const YEAR: TimeMap = { duration: 365 * DAY.duration, unit: 'year', shortUnit: 'y' }

const humanTimeMap = [SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR].slice().reverse()
const countdownTimeMap = [SECOND, MINUTE, HOUR, DAY, MONTH, YEAR].slice().reverse()

const props = withDefaults(
  defineProps<{
    time: Date
    locale?: string
    format?: 'countdown' | 'human'
    display?: 'narrow' | 'short' | 'long'
    numeric?: 'auto' | 'always'
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat
    // The narrow style could be similar to the short style for some locales.
    significantUnits?: number
  }>(),
  {
    format: 'human',
    display: 'short',
    numeric: 'auto',
    significantUnits: 4
  }
)

const globalStore = useGlobalStore()
const timeDifference = computed(() => props.time.getTime() - globalStore.time.getTime())

const humanTime = computed(() => {
  const closestHumanTimeMap = humanTimeMap.find(mapping => Math.abs(timeDifference.value) >= mapping.duration) || SECOND
  const humanTime = { ...closestHumanTimeMap, value: Math.floor(timeDifference.value / closestHumanTimeMap.duration) }

  return new Intl.RelativeTimeFormat(
    props.locale,
    {
      style: props.display,
      numeric: props.numeric
    })
    .format(
      humanTime.value, humanTime.unit
    )
})

const countdownTime = computed(() => {
  let remainingTime = Math.abs(timeDifference.value)

  const timeBreakdown = countdownTimeMap.reduce((breakdowns, timeMap) => {
    const value = Math.floor(remainingTime / timeMap.duration)
    remainingTime = remainingTime - value * timeMap.duration
    breakdowns.push({ ...timeMap, value })
    return breakdowns
  }, [] as (TimeMap & { value: number })[])

  while (timeBreakdown[0]?.value === 0) {
    timeBreakdown.shift()
  }

  const finalBreakdown = timeBreakdown.length > 0 ? timeBreakdown.slice(0, props.significantUnits) : [{ ...SECOND, value: 1 }]
  const timestamp = finalBreakdown.map(timeBreakdown => `${timeBreakdown.value}${timeBreakdown.shortUnit}`).join(' ')

  return `${timeDifference.value < 0 ? '-' : ''}${timestamp}`
})
</script>

<template>
  <span>{{ format === 'human' ? humanTime : countdownTime }}</span>
</template>
