<template>
  <div class="h-3 relative flex items-center">
    <input
      :id="id"
      :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
      class="pp-slider focus:outline-none rounded focus:ring-2 focus:ring-offset-4 focus:ring-brand-500 transition active:ring-0"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="modelValue"
      @input="emit('update:modelValue', parseFloat(($event as InputElementEvent).target.value))"
      @change="emit('change', parseFloat(($event as InputElementEvent).target.value))"
      :disabled="disabled"
    />

    <div
      :for="id"
      class="absolute inset-x-0 w-full top-1/2 transform -translate-y-1/2 overflow-hidden pointer-events-none"
      style="height: 2px"
    >
      <div
        class="bg-brand-800 h-full w-full pointer-events-none"
        :style="{ transform: `translateX(-${((max - modelValue) / (max - min)) * 100}%)` }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InputElementEvent } from '@/@types/InputElementEvent'
import { getCurrentInstance } from 'vue'

withDefaults(
  defineProps<{
    modelValue: number
    disabled?: boolean
    min?: number
    max?: number
    step?: number
  }>(),
  { disabled: false, min: 1, max: 100, step: 1 }
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
  (event: 'change', value: number): void
}>()

const instance = getCurrentInstance()
const id = `pp-slider-${instance?.uid}`
</script>
