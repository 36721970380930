<script setup lang="ts">
import type { PromiseMeta } from '@/@types/PromiseMeta'
import MoneyMarket from '@/domains/MoneyMarket'
import Token from '@/domains/Token'
import TokenAmount from '@/domains/TokenAmount'
import { ref } from 'vue'
import MoneyMarketCard from '@/components/MoneyMarketCard.vue'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import { isTestnet } from '@/ui-config/defaults'
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'

defineProps<{ market: MoneyMarket, fetchState: PromiseMeta<any> }>()
const openDepositModal = ref(false)
const openBorrowModal = ref(false)
const chainConnection = useChainConnectionStore()
const successCount = ref(0)
const handleSuccess = () => {
  openDepositModal.value = false
  openBorrowModal.value = false
  successCount.value++
}
</script>

<template>
  <pp-tr class="pp-card bg-cyan-900 bg-none">
    <pp-td valign="middle" class="min-w-36">
      <div class="whitespace-nowrap xl:-ml-8">
        <pp-token-img class="inline-block w-7 xl:w-12" :token="market.assetToken"></pp-token-img>

        <span
          class="ml-2 font-semibold tracking-wide xl:ml-3 xl:text-xl"
          v-text="market.assetToken.symbol"
        ></span>
      </div>
    </pp-td>

    <template v-if="fetchState.is('resolved-once')">
      <pp-td valign="middle" align="right">
        <div>
          <div class="text-lg">
            <pp-async-token-amount-valuation
              :token-amount="(market.details.response.totalTokenDeposited as TokenAmount)"
            ></pp-async-token-amount-valuation>
          </div>

          <div class="text-sm text-gray-300">
            <pp-formatted-number
              :value="market.details.response.totalTokenDeposited.formattedAmount"
              :max-decimals="2"
              :min-decimals="2"
              notation="compact"
            ></pp-formatted-number>
            <pp-token-symbol class="ml-2" :token="market.assetToken"></pp-token-symbol>
          </div>
        </div>
      </pp-td>
      <pp-td valign="middle" align="right">
        <div v-if="market.borrowable">
          <div class="text-lg">
            <pp-async-token-amount-valuation
              :token-amount="(market.details.response.totalTokenBorrowed as TokenAmount)"
            ></pp-async-token-amount-valuation>
          </div>

          <div class="text-sm text-gray-300">
            <pp-formatted-number
              :value="market.details.response.totalTokenBorrowed.formattedAmount"
              :max-decimals="2"
              :min-decimals="2"
              notation="compact"
            ></pp-formatted-number>
            <pp-token-symbol class="ml-2" :token="market.assetToken"></pp-token-symbol>
          </div>
        </div>

        <div v-else>-</div>
      </pp-td>
      <pp-td valign="middle" align="right" class="min-w-24 max-w-36">
        <div class="text-lg">
          <pp-percent :value="market.details.response.depositApy"></pp-percent>
        </div>
        <div v-if="!isTestnet" class="text-gray-300 whitespace-nowrap">
          <span class="inline-flex items-center text-sm truncate align-middle max-w-24">
            <pp-percent v-if="false" :value="market.details.response.depositPlyApy"></pp-percent>
          </span>
          <pp-token-img :token="Token.query().mustFindBy('symbol', 'PLY')" class="inline-block w-4"></pp-token-img>
          <span class="ml-1 align-middle">TBD</span>
        </div>
      </pp-td>
      <pp-td valign="middle" align="right" class="min-w-24 max-w-36">
        <div v-if="market.borrowable">
          <div class="text-lg">
            <pp-percent :value="market.details.response.borrowApy"></pp-percent>
          </div>
          <div v-if="!isTestnet" class="text-gray-300 whitespace-nowrap">
            <span class="inline-flex items-center text-sm truncate align-middle max-w-24">
              <pp-percent v-if="false" :value="market.details.response.borrowPlyApy"></pp-percent>
            </span>
            <pp-token-img
              :token="Token.query().mustFindBy('symbol', 'PLY')"
              class="inline-block w-4"
            ></pp-token-img>
            <span class="ml-1 align-middle">TBD</span>
          </div>
        </div>
        <div v-else>-</div>
      </pp-td>
    </template>

    <template v-else-if="fetchState.is('first-load') || fetchState.is('idle')">
      <pp-td valign="middle" align="right">
        <div>
          <pp-skeleton-loader class="w-16 h-6"></pp-skeleton-loader>
        </div>

        <div>
          <pp-skeleton-loader class="w-20 h-4"></pp-skeleton-loader>
        </div>
      </pp-td>

      <pp-td valign="middle" align="right">
        <div>
          <pp-skeleton-loader class="w-16 h-6"></pp-skeleton-loader>
        </div>

        <div>
          <pp-skeleton-loader class="w-20 h-4"></pp-skeleton-loader>
        </div>
      </pp-td>

      <pp-td valign="middle" align="right" class="min-w-24">
        <div>
          <pp-skeleton-loader class="w-16 h-6"></pp-skeleton-loader>
        </div>

        <div>
          <pp-skeleton-loader class="w-20 h-4"></pp-skeleton-loader>
        </div>
      </pp-td>

      <pp-td valign="middle" align="right" class="min-w-24">
        <div>
          <pp-skeleton-loader class="w-16 h-6"></pp-skeleton-loader>
        </div>

        <div>
          <pp-skeleton-loader class="w-20 h-4"></pp-skeleton-loader>
        </div>
      </pp-td>
    </template>

    <pp-td valign="middle" align="right">
      <div class="flex space-x-2">
        <div>
          <pp-modal v-model="openDepositModal">
            <template v-slot:activator="{ on }">
              <pp-btn
                v-bind="on"
                :disabled="fetchState.is('first-load') || !chainConnection.user || !chainConnection.user.details.is('resolved')"
                size="sm"
              >Deposit</pp-btn>
            </template>

            <MoneyMarketCard
              :key="successCount"
              @success="handleSuccess"
              v-if="chainConnection.user"
              :market="market"
              :user="chainConnection.user"
              page="Deposit"
            />
          </pp-modal>
        </div>

        <div>
          <pp-modal v-model="openBorrowModal">
            <template v-slot:activator="{ on }">
              <pp-btn
                v-bind="on"
                :disabled="fetchState.is('first-load') || !chainConnection.user || !chainConnection.user.details.is('resolved')"
                size="sm"
              >Borrow</pp-btn>
            </template>

            <MoneyMarketCard
              :key="successCount"
              @success="handleSuccess"
              v-if="chainConnection.user"
              :market="market"
              :user="chainConnection.user"
              page="Borrow"
            />
          </pp-modal>
        </div>
      </div>
    </pp-td>
  </pp-tr>
</template>
