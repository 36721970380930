<script setup lang="ts">
import QueryCollection from '@/domains/QueryCollection'
import type { UserMarketDetails } from '@/@types/UserMarketDetails'
import { computed } from 'vue'
import TokenAmount from '@/domains/TokenAmount'
import DepositsSectionItem from './DepositsSectionItem.vue'

const props = defineProps<{
  walletAddress?: string
  marketPositions: QueryCollection<UserMarketDetails>
}>()

const positions = computed(() => props.marketPositions.where('depositBalance', (balance: TokenAmount) => parseFloat(balance.formattedAmount) > 0))
</script>

<template>
  <div v-if="positions.length === 0" class="px-2">
    <p class="text-gray-50">No deposits yet.</p>
  </div>

  <pp-table v-else compact>
    <thead>
      <pp-tr>
        <pp-th valign="middle"></pp-th>
        <pp-th align="right" valign="middle">Balance</pp-th>
        <pp-th valign="middle">Collateral</pp-th>
        <pp-th align="right" valign="middle"></pp-th>
      </pp-tr>
    </thead>

    <tbody>
      <DepositsSectionItem
        v-for="position in positions"
        :key="position.market.assetToken.address"
        :position="position"
      />
    </tbody>
  </pp-table>
</template>
