<template>
  <div class="p-4 rounded-md" :class="styles[variant as AlertVariant].bg">
    <div class="flex">
      <div class="flex-shrink-0">
        <component
          :is="styles[variant as AlertVariant].icon"
          class="w-5 h-5"
          :class="styles[variant as AlertVariant].iconColor"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <slot name="header">
          <h3
            class="text-sm font-medium"
            :class="styles[variant as AlertVariant].header"
            v-text="header"
          ></h3>
        </slot>

        <div class="mt-2 text-sm" :class="styles[variant as AlertVariant].content">
          <slot name="content">
            <p v-text="content"></p>
          </slot>
        </div>
        <div
          v-if="dismissable || (actions as AlertAction[]).length > 0 || $slots.actions"
          class="mt-2"
        >
          <div class="flex -mx-2 space-x-2">
            <slot
              name="actions"
              :color="styles[variant as AlertVariant].color"
              :btn-attr="{ variant: 'text', size: 'sm', color: styles[variant as AlertVariant].color }"
            >
              <pp-btn
                v-for="action in actions"
                :key="action.text"
                @click="action.action"
                :color="styles[variant as AlertVariant].color"
              >{{ action.text }}</pp-btn>
            </slot>

            <pp-btn
              v-if="dismissable"
              @click="emit('dismiss')"
              variant="text"
              size="sm"
              :color="styles[variant as AlertVariant].color"
            >Dismiss</pp-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ButtonColor } from '@/@types/ButtonColor'
import {
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
  InformationCircleIcon
} from '@heroicons/vue/solid'
import type { RenderFunction, DefineComponent } from 'vue'

type AlertVariant = 'success' | 'error' | 'info' | 'warning'

type AlertStyle = {
  header: string
  content: string
  bg: string
  iconColor: string
  icon: RenderFunction | DefineComponent,
  color: ButtonColor
}

type AlertAction = {
  text: string
  action: () => void
}

withDefaults(
  defineProps<{
    header?: string,
    content?: string,
    variant?: AlertVariant,
    dismissable?: boolean,
    actions?: AlertAction[]
  }>(),
  {
    variant: 'info',
    header: 'Use the header slot or prop to override this header',
    content: 'Use the content slot or prop to elaborate',
    dismissable: false,
    actions: () => []
  }
)

const emit = defineEmits<{
  (event: 'dismiss'): void
}>()

const styles: Record<AlertVariant, AlertStyle> = {
  success: {
    header: 'text-white',
    content: 'text-gray-200',
    bg: 'bg-green-900/50',
    iconColor: 'text-green-200',
    icon: CheckCircleIcon,
    color: 'green'
  },
  error: {
    header: 'text-white',
    content: 'text-gray-200',
    bg: 'bg-red-900/50',
    iconColor: 'text-red-200',
    icon: XCircleIcon,
    color: 'red'
  },
  info: {
    header: 'text-white',
    content: 'text-gray-200',
    bg: 'bg-blue-900/50',
    iconColor: 'text-blue-200',
    icon: InformationCircleIcon,
    color: 'blue'
  },
  warning: {
    header: 'text-white',
    content: 'text-gray-200',
    bg: 'bg-yellow-600/50',
    iconColor: 'text-yellow-200',
    icon: ExclamationIcon,
    color: 'yellow'
  }
}

</script>
