import TokenAmount from '@/domains/TokenAmount'
import { ethers, BigNumber, providers, Signer } from 'ethers'
import Token from '../Token'
import type { ChainIdentity } from '@/@types/ChainIdentity'
import ERC20 from '@/domains/contracts/abis/erc20.json'
import BigNumberjs from 'bignumber.js'
import { waitForConfirmation } from '@/helpers/wait-for-confirmation'

const uint256limit = new BigNumberjs(2)
  .pow(new BigNumberjs(256))
  .minus(new BigNumberjs(1))
  .toFixed()

export default class TokenContract {
  public token
  public provider
  public signer

  constructor({ token, provider, signer }: { token: Token } & ChainIdentity) {
    this.token = token
    this.provider = provider
    this.signer = signer
  }

  async balanceOf(walletAddress: string | undefined) {
    if (walletAddress) {
      // NOTE: special case for ETH. SDK v0.4.6 not supported as of 14/10/2021
      if (this.token.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
        const balance = await this.provider.getBalance(walletAddress)
        const ethBalance = ethers.utils.formatEther(balance)
        return new TokenAmount(this.token, ethBalance, false)
      } else {
        const balance = await this._contract(this.provider).balanceOf(walletAddress)
        return new TokenAmount(this.token, balance.toString(), true)
      }
    } else {
      return new TokenAmount(this.token, '0', false)
    }
  }

  approve(
    spenderAddress: string,
    amount: TokenAmount
  ): Promise<providers.TransactionReceipt> {
    return new Promise((resolve, reject) => {
      this._contract(this.signer!)
        .approve(spenderAddress, amount.rawAmount)
        .then((response: providers.TransactionResponse) => {
          waitForConfirmation(response, {
            onConfirmed: ({ receipt }) => {
              resolve(receipt)
            }
          })
        })
        .catch((error: Error) => {
          reject(error)
        })
    })
  }

  allowance(
    walletAddress: string,
    spenderAddress: string
  ): Promise<TokenAmount> {
    if (walletAddress) {
      const skipApproval = ['all', spenderAddress].some((spender) =>
        this.token.ignoreApproval.includes(spender)
      )

      return skipApproval
        ? Promise.resolve(new TokenAmount(this.token, uint256limit, true))
        : new Promise((resolve, reject) => {
          this._contract(this.provider)
            .allowance(walletAddress, spenderAddress)
            .then((response: BigNumber) => {
              resolve(new TokenAmount(this.token, response.toString(), true))
            })
            .catch((error: Error) => reject(error))
        })
    } else {
      return Promise.resolve(new TokenAmount(this.token, '0', true))
    }
  }

  _contract(signerOrProvider: Signer | ethers.providers.Web3Provider | ethers.providers.BaseProvider) {
    return new ethers.Contract(
      this.token.address,
      ERC20,
      signerOrProvider
    )
  }
}
