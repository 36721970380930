import MoneyMarket from '../MoneyMarket'
import { MoneyMarket as SdkMoneyMarket, MoneyMarketReadWrite as SdkMoneyMarketReadWrite, SdkRead, MarketAction } from '@/plugins/sdk'
import PromiseHandler from '../PromiseHandler'
import TokenAmount from '../TokenAmount'
import type { ChainIdentity } from '@/@types/ChainIdentity'
import type { MoneyMarketDetails as SdkMoneyMarketDetails } from 'sdk-mainnet/dist/types'
import type { PromiseExecutionConfig } from '@/@types/PromiseExecutionConfig'
import type { MoneyMarketDetails } from '@/@types/MoneyMarketDetails'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import { UserFinancialStatus } from '@/@types/UserFinancialStatus'
import Valuation from '../Valuation'
import handleTransaction from '@/helpers/handle-transaction'
import type { TransactionResponse } from '@ethersproject/abstract-provider'
import notifier from '../NotificationContainer'

const marketActions = {
  deposit: MarketAction.Deposit,
  borrow: MarketAction.Borrow,
  withdraw: MarketAction.Withdraw,
  repay: MarketAction.Repay,
  enableCollateral: MarketAction.EnableCollateral,
  disableCollateral: MarketAction.DisableCollateral
} as const

type MarketActions = keyof typeof marketActions

export default class MoneyMarketContract {
  public signer
  public provider
  public moneyMarket

  constructor({ signer, provider, moneyMarket }: { moneyMarket: MoneyMarket } & ChainIdentity) {
    this.signer = signer
    this.provider = provider
    this.moneyMarket = moneyMarket
  }

  borrow(tokenAmount: TokenAmount) {
    return this.sdkMMRW
      .borrow(tokenAmount.toSdk())
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Borrow transaction pending confirmation',
          { title: `Successfully borrowed ${tokenAmount.formattedAmount} ${tokenAmount.token.symbol}`, text: '' }
        )
      })
  }

  deposit(tokenAmount: TokenAmount) {
    return this.sdkMMRW
      .deposit(tokenAmount.toSdk())
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Deposit transaction pending confirmation',
          { title: 'Deposit successful', text: `Sucessfully deposited ${tokenAmount.formattedAmount} ${tokenAmount.token.symbol}.` }
        )
      })
  }

  repay(tokenAmount: TokenAmount) {
    return this.sdkMMRW
      .repay(tokenAmount.toSdk())
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Repay transaction pending confirmation',
          { title: `Successfully repaid ${tokenAmount.rawAmount === '-1' ? tokenAmount.token.symbol : [tokenAmount.formattedAmount, tokenAmount.token.symbol].join(' ')}`, text: '' }
        )
      })
  }

  withdraw(tokenAmount: TokenAmount) {
    return this.sdkMMRW
      .withdraw(tokenAmount.toSdk())
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Withdraw transaction pending confirmation',
          { title: `Successfully withdrew ${tokenAmount.rawAmount === '-1' ? tokenAmount.token.symbol : [tokenAmount.formattedAmount, tokenAmount.token.symbol].join(' ')}`, text: '' }
        )
      })
  }

  enableCollateral() {
    return this.sdkMMRW
      .enableCollateral()
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Enable collateral transaction pending confirmation',
          { title: `Successfully enabled ${this.moneyMarket.assetToken.symbol} as collateral`, text: '' }
        )
      })
  }

  disableCollateral() {
    return this.sdkMMRW
      .disableCollateral()
      .then(response => {
        notifier.info({ title: 'Transaction submitted', text: 'Please wait for confirmation' })
        return this.handleTransaction(
          response,
          'Disable collateral transaction pending confirmation',
          { title: `Successfully disabled ${this.moneyMarket.assetToken.symbol} as collateral`, text: '' }
        )
      })
  }

  calcStatChanges(financialStatus: UserFinancialStatus, marketAction: MarketActions, tokenAmount?: TokenAmount) {
    const position = financialStatus.marketPositions.mustFindBy('market.assetToken.address', this.moneyMarket.assetToken.address)

    const changes = new SdkRead(this).calcHypotheticalStats({
      userMarketDetail: {
        market: position.market.receiptToken.address,
        depositBalance: position.depositBalance.toSdk(),
        borrowBalance: position.borrowBalance.toSdk(),
        isCollateral: position.isCollateral,
        maxWithdrawableAmount: position.maxWithdrawableAmount.toSdk(),
        collateralRatio: position.collateralRatio,
        underlyingPrice: position.underlyingPrice
      },
      currentBorrowLimit: { currency: financialStatus.borrowLimit.currency, amount: financialStatus.borrowLimit.amount.toString() },
      currentBorrowValuation: { currency: financialStatus.borrowedBalance.currency, amount: financialStatus.borrowedBalance.amount.toString() },
      action: marketActions[marketAction],
      tokenAmount: tokenAmount?.toSdk()
    })

    return {
      newBorrowLimit: new Valuation(changes.newBorrowLimit),
      newBorrowUtilisation: changes.newBorrowUtilization < 0 || changes.newBorrowUtilization >= 1 ? 1 : changes.newBorrowUtilization
    }
  }

  getDetails(options: PromiseExecutionConfig<SdkMoneyMarketDetails, MoneyMarketDetails> = {}) {
    new PromiseHandler(
      () => this.sdkMoneyMarket.read({
        provider: this.provider,
        signer: this.signer
      }).getDetails(),
      this.moneyMarket.details
    ).execute({
      ...options,
      transformResponse: (response) => ({
        totalTokenDeposited: new TokenAmount(this.moneyMarket.assetToken, response.totalTokenDeposited.rawAmount(), true),
        totalTokenBorrowed: new TokenAmount(this.moneyMarket.assetToken, response.totalTokenBorrowed.rawAmount(), true),
        depositApy: response.depositApy,
        depositPlyApy: response.depositPlyApy,
        borrowApy: response.borrowApy,
        borrowPlyApy: response.borrowPlyApy,
        collateralRatio: response.collateralRatio
      })
    })

    return this.moneyMarket
  }

  private get sdkMoneyMarket() {
    return new SdkMoneyMarket(this.moneyMarket.receiptToken.address, this.moneyMarket.assetToken.address)
  }

  private get sdkMMRW() {
    return new SdkMoneyMarketReadWrite(
      this,
      this.moneyMarket.receiptToken.address,
      this.moneyMarket.assetToken.address
    )
  }

  private updateUserMarketDetails() {
    const chainConnection = useChainConnectionStore()
    chainConnection.user?.getDetails?.()
  }

  private handleTransaction(response: TransactionResponse, submitted: string, success: { title: string, text: string }) {
    handleTransaction(
      response,
      submitted,
      success,
      {
        onConfirmed: () => {
          this.getDetails()
          this.updateUserMarketDetails()
        }
      })
  }
}
