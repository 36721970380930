<script setup lang="ts">
import { UserMarketDetails } from '@/@types/UserMarketDetails'
import { ref } from 'vue'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import DisableCollateralForm from '../forms/DisableCollateralForm.vue'

const chainConnection = useChainConnectionStore()

defineProps<{
  position: UserMarketDetails
}>()

const openCollateralModal = ref(false)
const successCount = ref(0)
const handleSuccess = () => {
  openCollateralModal.value = false
  successCount.value++
}
</script>

<template>
  <pp-tr>
    <pp-td valign="middle">
      <div class="flex items-center space-x-2">
        <pp-token-img :token="position.market.assetToken" class="inline-block w-6 h-6"></pp-token-img>
        <pp-token-symbol :token="position.market.assetToken"></pp-token-symbol>
      </div>
    </pp-td>

    <pp-td valign="middle" align="right">Active</pp-td>

    <pp-td valign="middle" align="right">
      <pp-modal v-model="openCollateralModal">
        <template v-slot:activator="{ on }">
          <pp-btn
            v-bind="on"
            size="sm"
            :disabled="chainConnection.user!.details.is('loading') || position.borrowBalance.gt0()"
          >Turn off</pp-btn>
        </template>

        <div>
          <DisableCollateralForm
            version="turn-off"
            :market="position.market"
            :user="chainConnection.user!"
            @success="handleSuccess"
          />
        </div>
      </pp-modal>
    </pp-td>
  </pp-tr>
</template>
