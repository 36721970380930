import type { ChainIdentity } from '@/@types/ChainIdentity'
import { SdkRead, SdkReadWrite } from '@/plugins/sdk'
import TokenAmount from '@/domains/TokenAmount'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import Valuation from './Valuation'
import Token from './Token'
import type { PromiseExecutionConfig } from '@/@types/PromiseExecutionConfig'
import type { UserDetails as SdkUserDetails, UserMarketDetails as SdkUserMarketDetails } from 'sdk-mainnet/dist/types'
import type { UserDetails } from '@/@types/UserDetails'
import type { UserMarketDetails } from '@/@types/UserMarketDetails'
import MoneyMarket from './MoneyMarket'
import QueryCollection from './QueryCollection'
import { MAX_MARKET_POSITIONS } from '@/ui-config/defaults'

const plyToken = Token.query().mustFindBy('symbol', 'PLY')

const findOrCreateUserMarketDetails = (sdkMarketDetails: SdkUserMarketDetails[]) => {
  const moneyMarkets = MoneyMarket.query().toArray()
  const responseMarkets = QueryCollection.from(sdkMarketDetails)

  return QueryCollection.from(
    moneyMarkets.map(market => {
      const responseMarket = responseMarkets.findBy('market', market.assetToken.address)

      return {
        market,
        depositBalance: new TokenAmount(market.assetToken, responseMarket ? responseMarket.depositBalance.rawAmount() : 0, true),
        borrowBalance: new TokenAmount(market.assetToken, responseMarket ? responseMarket.borrowBalance.rawAmount() : 0, true),
        isCollateral: responseMarket ? responseMarket.isCollateral : false,
        maxWithdrawableAmount: new TokenAmount(market.assetToken, responseMarket ? responseMarket.maxWithdrawableAmount.rawAmount() : 0, true),
        collateralRatio: responseMarket ? responseMarket.collateralRatio : 0,
        underlyingPrice: responseMarket ? responseMarket.underlyingPrice : '0'
      }
    })
  )
}

type UserConstructor = {
  address: string
  details?: {
    response: {
      marketPositions: QueryCollection<UserMarketDetails>,
      borrowLimit: Valuation,
      accruedPly: TokenAmount,
      lockedPly: TokenAmount,
      borrowableAmount: Valuation
    }
  }
} & ChainIdentity

export default class User {
  public address
  public signer
  public provider
  public details

  constructor({ address, signer, provider, details }: UserConstructor) {
    this.address = address
    this.signer = signer
    this.provider = provider
    this.details = createState(details || {
      response: {
        marketPositions: findOrCreateUserMarketDetails([]),
        borrowLimit: new Valuation({ currency: 'USD', amount: 0 }),
        accruedPly: new TokenAmount(plyToken, 0),
        lockedPly: new TokenAmount(plyToken, 0),
        borrowableAmount: new Valuation({ currency: 'USD', amount: 0 })
      }
    })
  }

  get status() {
    const { response } = this.details
    return {
      ...response,
      get depositedBalance(): Valuation {
        return response.marketPositions
          .map((position: UserMarketDetails) => position.depositBalance.valuation)
          .reduce(
            (acc, val) => acc.plus(val),
            new Valuation({ currency: 'USD', amount: 0 })
          )
      },
      get borrowedBalance(): Valuation {
        return response.marketPositions
          .map((position: UserMarketDetails) => position.borrowBalance.valuation)
          .reduce(
            (acc, val) => acc.plus(val),
            new Valuation({ currency: 'USD', amount: 0 })
          )
      },
      get netBalance(): Valuation {
        return this.depositedBalance.minus(this.borrowedBalance)
      },
      get liquidationThreshold(): Valuation {
        return this.depositedBalance.minus(this.borrowedBalance)
      },
      get borrowUtilisation(): string | number {
        const value = this.borrowedBalance.div(response.borrowLimit)
        return value === 'NaN' ? 0 : value
      },
      get hasMaxedMarket(): boolean {
        return this.marketPositions.where('isCollateral', true).length >= MAX_MARKET_POSITIONS
      }
    }
  }

  getDetails(options: PromiseExecutionConfig<SdkUserDetails, UserDetails> = {}) {
    this._detailsFetcher.execute(
      {
        ...options,
        transformResponse: (response) => {
          return {
            marketPositions: findOrCreateUserMarketDetails(response.markets),
            accruedPly: new TokenAmount(plyToken, response.accruedPly.rawAmount(), true),
            lockedPly: new TokenAmount(plyToken, response.lockedPly.rawAmount(), true),
            borrowLimit: new Valuation({ currency: 'USD', amount: response.borrowLimit.amount }),
            borrowableAmount: new Valuation({ currency: 'USD', amount: response.borrowableAmount.amount })
          }
        }
      })
    return this
  }

  claimPly() {
    return new SdkReadWrite(this).claim()
  }

  private get _detailsFetcher() {
    return new PromiseHandler(
      () => new SdkRead(this).getUserDetails(this.address),
      this.details
    )
  }
}
