<script setup lang="ts">
import { computed } from 'vue'
import NotificationContainer from '@/domains/NotificationContainer'
import ToastItem from './ToastItem.vue'
const notifications = computed(() => NotificationContainer.notifications.value.sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
</script>

<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition-group
        enter-active-class="transition transform"
        enter-from-class="opacity-0 translate-y-5"
        enter-to-class="opacity-100"
        leave-active-class="transition transform duration-300"
        leave-to-class="translate-x-5 opacity-0"
      >
        <toast-item
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
        ></toast-item>
      </transition-group>
    </div>
  </div>
</template>
