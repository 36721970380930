import { AURORA_PARAMS } from '@/app-config/metamask'

const switchNetwork = async (): Promise<void> => {
  try {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: AURORA_PARAMS.chainId }]
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((switchError as any).code === 4902) {
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [AURORA_PARAMS]
        })
      } catch (addError) {
        console.error(`Error adding ${import.meta.env.VITE_NETWORK_NAME}`, addError)
      }
    }
  }
}

export default switchNetwork
