export default {
  fill: {
    base:
      'rounded-md focus:ring-red-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'bg-red-600 text-white',
      secondary: 'text-red-100'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  highlight: {
    base: 'rounded-md focus:ring-red-500 disabled:text-gray-400',
    active: {
      primary: 'bg-red-100 text-red-700',
      secondary: 'text-red-700'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  bold: {
    base: 'focus:ring-red-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'text-red-600',
      secondary: 'text-red-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  outline: {
    base:
      'text-red-700 focus:ring-red-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'font-semibold border-2 border-red-500 focus:ring-red-200',
      secondary: 'text-red-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  }
}
