<template>
  <span>{{ formattedDate }}</span>
</template>

<script setup lang="ts">
import { computed } from 'vue'
const props = withDefaults(
  defineProps<{
    date: Date | string | number
    locale?: string
    format?: 'full' | 'long' | 'medium' | 'short'
  }>(),
  {
    format: 'medium',
    locale: 'en-US'
  }
)
const formattedDate = computed(
  () => new Intl.DateTimeFormat(
    props.locale,
    { dateStyle: props.format, timeZone: 'UTC' }
  )
    .format(new Date(props.date))
)
</script>
