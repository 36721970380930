<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    value: string | number
    staticLabel?: boolean
  }>(),
  { staticLabel: false }
)

const currentProgress = computed(() => (100 * +props.value).toFixed(2))
const offset = computed(() => parseInt((100 - (100 * parseFloat(props.value as string))).toString()))
</script>

<template>
  <div>
    <div>
      <div v-if="staticLabel" class="text-xs text-right">
        <pp-percent :value="parseFloat(currentProgress) / 100"></pp-percent>
      </div>
      <div
        v-else
        class="flex justify-end text-xs transition translate"
        :style="`transform: translateX(${(-offset > 0) ? 0 : -offset}%)`"
      >
        <div class="transform translate-x-1/2">
          <slot>{{ currentProgress }}%</slot>
        </div>
      </div>
    </div>
    <div
      class="h-2 mt-1 overflow-hidden text-xs bg-gray-200 rounded-full"
      role="progressbar"
      :aria-valuenow="currentProgress"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="h-full py-0.5 px-2 bg-brand-500 transition text-white font-semibold rounded-full flex items-center justify-end"
        :style="{ transform: `translateX(${(-offset > 0) ? 0 : - offset}%)` }"
      ></div>
    </div>
  </div>
</template>
