export default {
  fill: 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 disabled:text-gray-400 disabled:bg-gray-200',
  text: 'text-blue-800 shadow-none hover:bg-blue-100 focus:ring-blue-500 disabled:text-gray-400 disabled:bg-transparent',
  outline:
    'border border-blue-800 text-blue-800 hover:bg-blue-100 focus:ring-blue-500 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent',
  gradient:
    'bg-gradient-to-r fix-gradient from-blue-500 via-blue-700 to-blue-500 bg-pos-0 bg-size-200 text-white hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-blue-500 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 disabled:text-gray-400',
  accent:
    'text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500 disabled:bg-gray-200 disabled:text-gray-400'
}
