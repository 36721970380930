<script setup lang="ts">
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import { SdkReadWrite } from '@/plugins/sdk'
import { computed, reactive } from 'vue'
import notifier from '@/domains/NotificationContainer'
import { CurrencyDollarIcon } from '@heroicons/vue/solid'
import { isTestnet } from '@/ui-config/defaults'

const chainConnection = useChainConnectionStore()
const faucetState = reactive(createState())

const faucetDrip = computed(() => new PromiseHandler(
  // @ts-ignore
  () => new SdkReadWrite(chainConnection.user!).faucetDrip(),
  faucetState
))
</script>

<template>
  <pp-btn
    v-if="isTestnet && chainConnection.user"
    @click="faucetDrip.execute({
      success: () => {
        notifier.success({ title: 'Successfully acquired test funds', text: 'Funds have been deposited into your wallet' })
        chainConnection.user?.getDetails()
      },
      error: (error) => {
        notifier.error({
          title: 'Unable to acquire test funds',
          text: error.message.includes('User call drips too many times')
            ? 'You cannot use the faucet more than 10 times'
            : 'Please try again in a few minutes'
        })
      }
    })
    "
    :loading="faucetDrip.state.is('loading')"
    class="bg-gradient-to-l hover:gradient-jade gradient-silk !bg-transparent fix-gradient"
  >
    Faucet
    <CurrencyDollarIcon class="w-5 h-5 ml-1 -mr-1" />
  </pp-btn>
</template>
