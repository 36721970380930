import '@/plugins/polyfills'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { registerGlobalComponent } from '@/plugins/registerComponents'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'
import { useChainConnectionStore } from './pinia/chain-connection'
import { useGlobalStore } from './pinia/global'
import notifier from './domains/NotificationContainer'
import BigNumber from 'bignumber.js'
import { isTestnet } from './ui-config/defaults'
BigNumber.config({ DECIMAL_PLACES: 50 })

const app = createApp(App)
registerGlobalComponent(app)
app.use(store).use(router).use(createPinia()).mount('#app')

const chainConnection = useChainConnectionStore()
const global = useGlobalStore()
if (!isTestnet) chainConnection.init()
global.init()

if (import.meta.env.MODE === 'development') {
  (window as any).chainConnection = chainConnection;
  (window as any).notifier = notifier
}
