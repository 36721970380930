<script setup lang="ts">
import MoneyMarket from '@/domains/MoneyMarket'
import User from '@/domains/User'
import { ref, watch } from 'vue'
import TokenAmount from '@/domains/TokenAmount'
import DepositForm from './forms/DepositForm.vue'
import BorrowForm from './forms/BorrowForm.vue'
import WithdrawForm from './forms/WithdrawForm.vue'
import RepayForm from './forms/RepayForm.vue'

const props = defineProps<{
  market: MoneyMarket
  page: 'Deposit' | 'Borrow' | 'Withdraw' | 'Repay'
  user: User
}>()

const emit = defineEmits<{
  (event: 'success'): void
}>()

const pages = ['Deposit', 'Borrow', 'Withdraw', 'Repay'] as const
const activePage = ref(pages.indexOf(props.page))
const handleSuccess = () => {
  emit('success')
}

const balanceKey = ref(0)

watch(() => props.user.details.state, (newVal) => {
  if (newVal === 'loading') {
    balanceKey.value++
  }
})

const position = props.user.status.marketPositions.mustFindBy('market.assetToken.address', props.market.assetToken.address)
</script>

<template>
  <pp-async-token-balance
    :key="balanceKey"
    :token="market.assetToken"
    :wallet-address="user.address"
    with-valuation
    image
    v-slot="{ state, execute }"
  >
    <pp-card class="!gradient-navy">
      <pp-card-body>
        <pp-left-right>
          <template #left>
            <pp-stat header="Wallet balance">
              <template #value>
                <template v-if="state.is('loading')">
                  <div>
                    <pp-skeleton-loader class="w-10 h-5"></pp-skeleton-loader>
                  </div>

                  <div class="-mt-3">
                    <pp-skeleton-loader class="h-3"></pp-skeleton-loader>
                  </div>
                </template>

                <pp-token-amount
                  v-else-if="state.is('resolved')"
                  :token-amount="(state.response as TokenAmount)"
                  with-valuation
                  image
                ></pp-token-amount>

                <pp-btn
                  v-else-if="state.is('rejected')"
                  @click="execute"
                  color="red"
                  variant="accent"
                >Retry</pp-btn>
              </template>
            </pp-stat>
          </template>

          <template #right>
            <pp-stat
              v-if="['Borrow', 'Repay'].includes(pages[activePage])"
              header="Borrowed"
              class="text-right"
            >
              <template #value>
                <pp-token-amount
                  with-valuation
                  image
                  :token-amount="user.status.marketPositions.mustFindBy('market.assetToken.address', market.assetToken.address).borrowBalance"
                ></pp-token-amount>
              </template>
            </pp-stat>

            <pp-stat v-else header="Deposited" class="text-right">
              <template #value>
                <pp-token-amount
                  with-valuation
                  image
                  :token-amount="user.status.marketPositions.mustFindBy('market.assetToken.address', market.assetToken.address).depositBalance"
                ></pp-token-amount>
              </template>
            </pp-stat>
          </template>
        </pp-left-right>
      </pp-card-body>

      <pp-card-body>
        <pp-tab-section :tabs="[...pages]" v-model="activePage" activable-class="!px-1 md:px-3">
          <div>
            <pp-require-wallet v-slot="{ user }">
              <DepositForm
                @success="handleSuccess()"
                :market="market"
                :user="user"
                :token-balance="(state.response as TokenAmount)"
              />
            </pp-require-wallet>
          </div>
          <div>
            <pp-require-wallet v-slot="{ user }">
              <BorrowForm
                :user="user"
                :borrow-limit="user.status.borrowLimit"
                :borrow-utilisation="user.status.borrowUtilisation"
                @success="handleSuccess()"
                :market="market"
              />
            </pp-require-wallet>
          </div>
          <div>
            <pp-require-wallet v-slot="{ user }">
              <WithdrawForm
                :user="user"
                @success="handleSuccess()"
                :market="market"
                :position="position"
              />
            </pp-require-wallet>
          </div>
          <div>
            <pp-require-wallet v-slot="{ user }">
              <RepayForm
                :user="user"
                @success="handleSuccess()"
                :market="market"
                :position="position"
                :token-balance="(state.response as TokenAmount)"
              />
            </pp-require-wallet>
          </div>
        </pp-tab-section>
      </pp-card-body>
    </pp-card>
  </pp-async-token-balance>
</template>
