
<script setup lang="ts">
import { computed } from 'vue'
import BN from 'bignumber.js'

const unitMap = [
  { value: 1_000_000_000_000_000, symbol: 'Quadrillion' },
  { value: 1_000_000_000_000, symbol: 'Trillion' },
  { value: 1_000_000_000, symbol: 'Billion' },
  { value: 1_000_000, symbol: 'Million' },
  { value: 1_000, symbol: 'K' }
]

const props = withDefaults(
  defineProps<{
    value: number | string | (Record<string, unknown> & { toString: () => string }),
    maxDecimals?: number
    minDecimals?: number
    currency?: string
    locale?: string
    notation?: 'standard' | 'compact'
    compactDisplay?: 'short' | 'long'
  }>(),
  {
    maxDecimals: 3,
    minDecimals: 3,
    locale: 'en-US',
    notation: 'standard',
    compactDisplay: 'short'
  }
)

const formattedNumber = computed(() => {
  const defaultFormatOptions = {
    minimumFractionDigits: props.minDecimals,
    maximumFractionDigits: props.minDecimals > props.maxDecimals ? props.minDecimals : props.maxDecimals
  }

  const fallbackUnitValue = { value: 1, symbol: '' }
  const unitValue = props.notation === 'compact'
    ? unitMap.find(comparison => new BN(props.value.toString()).abs().gte(new BN(comparison.value))) || fallbackUnitValue
    : fallbackUnitValue
  const displayNumber = props.notation === 'compact'
    ? new BN(props.value.toString()).dividedBy(new BN(unitValue.value)).toFixed()
    : props.value.toString()

  const formattedNumber = new Intl.NumberFormat(
    props.locale,
    props.currency
      ? { style: 'currency', currency: props.currency, ...defaultFormatOptions }
      : defaultFormatOptions
  ).format(parseFloat(displayNumber))

  const unit = props.compactDisplay === 'short'
    ? unitValue.symbol.slice(0, 1)
    : unitValue.symbol

  return formattedNumber.includes('∞') ? 'Infinity' : [formattedNumber, unit].filter(s => s).join(' ')
})
</script>

<template>
  <span v-text="formattedNumber" :title="value.toString()" class="whitespace-nowrap"></span>
</template>
