import type { ChainIdentity } from '@/@types/ChainIdentity'
import MoneyMarketCollection from './collections/MoneyMarketCollection'
import MoneyMarketContract from './contracts/MoneyMarketContract'
import Token from './Token'
import { createState } from './PromiseHandler'
import TokenAmount from './TokenAmount'

type MoneyMarketConstructor = {
  assetToken: Token
  receiptToken: Token
  borrowable: boolean
  inactive: boolean
}

export default class MoneyMarket {
  public assetToken
  public receiptToken
  public borrowable
  public details
  public inactive

  constructor({ assetToken, receiptToken, borrowable, inactive }: MoneyMarketConstructor) {
    this.assetToken = assetToken
    this.receiptToken = receiptToken
    this.borrowable = borrowable
    this.inactive = inactive
    this.details = createState({
      response: {
        totalTokenDeposited: new TokenAmount(this.assetToken, 0),
        totalTokenBorrowed: new TokenAmount(this.assetToken, 0),
        depositApy: 0,
        depositPlyApy: 0,
        borrowApy: 0,
        borrowPlyApy: 0,
        collateralRatio: 0
      }
    })
  }

  static query() {
    return MoneyMarketCollection.all()
  }

  contract({ signer, provider }: ChainIdentity) {
    return new MoneyMarketContract({ signer, provider, moneyMarket: this })
  }
}
