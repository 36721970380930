<script setup lang="ts">
import { computed } from 'vue'
import type { TabColor } from '@/@types/TabColor'
import type { TabVariant } from '@/@types/TabVariant'
import type { GenericComponentSize } from '@/@types/GenericComponentSize'
import TabColors from '@/ui-config/tab-colors'
import ButtonSizes from '@/ui-config/button-sizes'
import IconButtonSizes from '@/ui-config/icon-button-sizes'

const props = withDefaults(
  defineProps<{
    as?: string,
    color?: TabColor,
    variant?: TabVariant,
    active?: boolean,
    size?: GenericComponentSize,
    icon?: boolean
  }>(),
  {
    as: 'button',
    color: 'brand',
    variant: 'bold',
    active: false,
    size: 'md',
    icon: false
  }
)

const selectedVariant = computed(() => TabColors[props.color][props.variant])
const activeClassnames = computed(() => selectedVariant.value.active)
</script>

<template>
  <component
    :is="as"
    class="pp-activable"
    v-bind="$attrs"
    :class="[
      active
        ? activeClassnames.primary
        : selectedVariant.inactive,
      selectedVariant.base,
      icon ? IconButtonSizes[size] : ButtonSizes[size]
    ]"
  >
    <slot :accent-class="activeClassnames.secondary"></slot>
  </component>
</template>
