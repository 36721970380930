import { providers } from 'ethers'
import { BLOCK_CONFIRMATION_COUNT } from '@/ui-config/defaults'

export const waitForConfirmation = <T, S>(
  transaction: providers.TransactionResponse,
  {
    onProgress,
    onConfirmed
  }: { onProgress?: ReceiptHandler<T>; onConfirmed?: ReceiptHandler<S> } = {}
) => {
  for (let i = 0; i < BLOCK_CONFIRMATION_COUNT; i++) {
    const blockCount = i + 1

    transaction.wait(blockCount).then(response => {
      if (onProgress) {
        onProgress({
          progress: {
            blocksConfirmed: blockCount,
            blocksRequired: BLOCK_CONFIRMATION_COUNT
          },
          receipt: response
        })
      }
    })
  }

  return new Promise((resolve, reject) => {
    transaction.wait(BLOCK_CONFIRMATION_COUNT)
      .then(response => {
        setTimeout(() => {
          if (onConfirmed) {
            onConfirmed({
              progress: {
                blocksConfirmed: BLOCK_CONFIRMATION_COUNT,
                blocksRequired: BLOCK_CONFIRMATION_COUNT
              },
              receipt: response
            })
          }

          resolve(transaction)
        }, 8000)
      })
      .catch(error => reject(error))
  })
}

export default waitForConfirmation
