<script setup lang="ts">
import { UserMarketDetails } from '@/@types/UserMarketDetails'
import { ref } from 'vue'
import MoneyMarketCard from '../MoneyMarketCard.vue'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import DisableCollateralForm from '../forms/DisableCollateralForm.vue'
import EnableCollateralForm from '../forms/EnableCollateralForm.vue'

const chainConnection = useChainConnectionStore()

defineProps<{
  position: UserMarketDetails
}>()

const openMoneyMarketModal = ref(false)
const openCollateralModal = ref(false)
const successCount = ref(0)
const handleSuccess = () => {
  openMoneyMarketModal.value = false
  openCollateralModal.value = false
  successCount.value++
}
</script>

<template>
  <pp-tr>
    <pp-td valign="middle">
      <div class="flex items-center space-x-2">
        <pp-token-img :token="position.market.assetToken" class="inline-block w-6 h-6"></pp-token-img>
        <pp-token-symbol :token="position.market.assetToken"></pp-token-symbol>
      </div>
    </pp-td>

    <pp-td align="right" valign="middle">
      <pp-async-token-amount-valuation :token-amount="position.depositBalance"></pp-async-token-amount-valuation>

      <div class="text-xs text-gray-300">
        <pp-token-amount :token-amount="position.depositBalance"></pp-token-amount>
      </div>
    </pp-td>

    <pp-td valign="middle">
      <pp-modal v-model="openCollateralModal">
        <template v-slot:activator="{ on }">
          <pp-toggle-btn
            v-bind="on"
            :model-value="position.isCollateral"
            :disabled="chainConnection.user!.details.is('loading')"
          ></pp-toggle-btn>
        </template>

        <div>
          <DisableCollateralForm
            v-if="position.isCollateral"
            :market="position.market"
            :user="chainConnection.user!"
            @success="handleSuccess"
          />
          <EnableCollateralForm
            v-else
            :market="position.market"
            :user="chainConnection.user!"
            @success="handleSuccess"
          />
        </div>
      </pp-modal>
    </pp-td>

    <pp-td align="right" valign="middle">
      <pp-modal v-model="openMoneyMarketModal">
        <template v-slot:activator="{ on }">
          <pp-btn
            v-bind="on"
            size="sm"
            :disabled="chainConnection.user!.details.is('loading')"
          >Withdraw</pp-btn>
        </template>

        <MoneyMarketCard
          v-if="chainConnection.user"
          :market="position.market"
          :user="chainConnection.user"
          page="Withdraw"
          @success="handleSuccess"
          :key="successCount"
        />
      </pp-modal>
    </pp-td>
  </pp-tr>
</template>
