<script setup lang="ts">
import type { PromiseMeta } from '@/@types/PromiseMeta'
import { computed } from 'vue'
import MarketTableItem from './MarketTableItem.vue'

const props = defineProps<{
  fetchState: PromiseMeta<any>
}>()

const markets = computed(() => props.fetchState.response)
</script>

<template>
  <div class="overflow-auto xl:pl-3 xl:overflow-visible">
    <pp-table class="overflow-visible">
      <thead>
        <pp-tr>
          <pp-th valign="middle">Assets</pp-th>
          <pp-th valign="middle" align="right">Total Deposited</pp-th>
          <pp-th valign="middle" align="right">Total Borrowed</pp-th>
          <pp-th valign="middle" align="right">Deposit APY</pp-th>
          <pp-th valign="middle" align="right">Borrow APY</pp-th>
          <pp-th></pp-th>
        </pp-tr>
      </thead>

      <tbody>
        <template v-for="market in markets" :key="market.assetToken.name">
          <MarketTableItem v-if="!market.inactive" :market="market" :fetch-state="fetchState" />
        </template>
      </tbody>
    </pp-table>
  </div>
</template>
