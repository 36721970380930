export default {
  fill: {
    base:
      'rounded-md focus:ring-brand-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'bg-brand-600 text-white',
      secondary: 'text-brand-100'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  highlight: {
    base: 'rounded-md focus:ring-brand-500 disabled:text-gray-400',
    active: {
      primary: 'bg-brand-100 text-brand-700',
      secondary: 'text-brand-700'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  bold: {
    base: 'focus:ring-brand-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'text-brand-600',
      secondary: 'text-brand-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  outline: {
    base:
      'text-brand-700 focus:ring-brand-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'font-semibold border-2 border-brand-500 focus:ring-brand-200',
      secondary: 'text-brand-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  }
}
