import Token from '@/domains/Token'

export default [
  // {
  //   assetToken: Token.query().mustFindBy('symbol', 'AURORA'),
  //   receiptToken: Token.query().mustFindBy('symbol', 'auAURORA')
  // },
  {
    assetToken: Token.query().mustFindBy('symbol', 'WNEAR'),
    receiptToken: Token.query().mustFindBy('symbol', 'auWNEAR'),
    inactive: false
  },
  {
    assetToken: Token.query().mustFindBy('symbol', 'USDC'),
    receiptToken: Token.query().mustFindBy('symbol', 'auUSDC'),
    inactive: false
  },
  {
    assetToken: Token.query().mustFindBy('symbol', 'USDT'),
    receiptToken: Token.query().mustFindBy('symbol', 'auUSDT'),
    inactive: false
  },
  // {
  //   assetToken: Token.query().mustFindBy('symbol', 'DAI'),
  //   receiptToken: Token.query().mustFindBy('symbol', 'auDAI'),
  //   inactive: false
  // },
  {
    assetToken: Token.query().mustFindBy('symbol', 'ETH'),
    receiptToken: Token.query().mustFindBy('symbol', 'auETH'),
    inactive: false
  },
  {
    assetToken: Token.query().mustFindBy('symbol', 'WBTC'),
    receiptToken: Token.query().mustFindBy('symbol', 'auWBTC'),
    inactive: false
  },
  {
    assetToken: Token.query().mustFindBy('symbol', 'PLY'),
    receiptToken: Token.query().mustFindBy('symbol', 'auPLY'),
    inactive: true
  }
].map(constructor => ({
  ...constructor,
  network: 'aurora',
  borrowable: constructor.assetToken.symbol !== 'PLY'
}))
