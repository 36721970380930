import BigNumber from 'bignumber.js'
import { CurrencyAmount } from '@/plugins/sdk'

type FormatOptions = {
  locale?: string
  notation?: 'standard' | 'compact'
  compactDisplay?: 'long' | 'short'
}

export default class Valuation {
  public currency
  public amount

  constructor({
    currency,
    amount
  }: {
    currency: string
    amount: number | string
  }) {
    this.currency = currency
    // eslint-disable-next-line
    this.amount = amount == 0 ? 0 : amount
  }

  get strAmount () {
    return this.amount.toString()
  }

  static fromSdk(valuation: CurrencyAmount) {
    return new Valuation({ currency: valuation.currency.toUpperCase(), amount: valuation.amount })
  }

  plus(valuation: Valuation) {
    if (this.currency !== valuation.currency) throw new Error('Cannot perform arithmetic across different currency')

    return new Valuation({
      currency: this.currency,
      amount: new BigNumber(this.amount)
        .plus(new BigNumber(valuation.amount))
        .toString()
    })
  }

  minus(valuation: Valuation) {
    if (this.currency !== valuation.currency) throw new Error('Cannot perform arithmetic across different currency')

    return new Valuation({
      currency: this.currency,
      amount: new BigNumber(this.amount)
        .minus(new BigNumber(valuation.amount))
        .toString()
    })
  }

  div(value: Valuation): string
  div(value: number | string): Valuation
  div(value: Valuation | number | string) {
    if (typeof value === 'string' || typeof value === 'number') {
      return new Valuation({
        currency: this.currency,
        amount: new BigNumber(this.amount).div(
          new BigNumber(value)
        ).toString()
      })
    } else {
      if (this.currency !== value.currency) throw new Error('Cannot perform arithmetic across different currency')

      return new BigNumber(this.amount)
        .div(new BigNumber(value.amount))
        .toString()
    }
  }

  mul(multiplier: number | string) {
    const amount = new BigNumber(this.amount)
      .multipliedBy(new BigNumber(multiplier))
      .toString()

    return new Valuation({ currency: this.currency, amount })
  }

  format(options: FormatOptions = {}) {
    return Intl.NumberFormat(options.locale || 'en-US', {
      style: 'currency',
      currency: this.currency,
      notation: options.notation || 'standard',
      compactDisplay: options.compactDisplay || 'short'
    }).format(parseFloat(this.amount.toString()))
  }

  gt(valuation: Valuation) {
    return new BigNumber(this.amount).gt(new BigNumber(valuation.amount))
  }

  lt(valuation: Valuation) {
    return new BigNumber(this.amount).lt(new BigNumber(valuation.amount))
  }

  gte(valuation: Valuation) {
    return new BigNumber(this.amount).gte(new BigNumber(valuation.amount))
  }

  lte(valuation: Valuation) {
    return new BigNumber(this.amount).lte(new BigNumber(valuation.amount))
  }

  gt0() {
    return new BigNumber(this.amount).gt(new BigNumber(0))
  }

  lt0() {
    return new BigNumber(this.amount).lt(new BigNumber(0))
  }

  gte0() {
    return new BigNumber(this.amount).gte(new BigNumber(0))
  }

  lte0() {
    return new BigNumber(this.amount).lte(new BigNumber(0))
  }
}
