export default {
  fill: {
    base:
      'rounded-md focus:ring-blue-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'bg-blue-600 text-white',
      secondary: 'text-blue-100'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  highlight: {
    base: 'rounded-md focus:ring-blue-500 disabled:text-gray-400',
    active: {
      primary: 'bg-blue-100 text-blue-700',
      secondary: 'text-blue-700'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  bold: {
    base: 'focus:ring-blue-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'text-blue-600',
      secondary: 'text-blue-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  outline: {
    base:
      'text-blue-700 focus:ring-blue-500 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'font-semibold border-2 border-blue-500 focus:ring-blue-200',
      secondary: 'text-blue-400'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  }
}
