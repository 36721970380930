export default {
  fill: 'text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 disabled:text-gray-400 disabled:bg-gray-200',
  text: 'text-yellow-800 shadow-none hover:bg-yellow-100 focus:ring-yellow-500 disabled:text-gray-400 disabled:bg-transparent',
  outline:
    'border border-yellow-800 text-yellow-800 hover:bg-yellow-100 focus:ring-yellow-500 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent',
  gradient:
    'bg-gradient-to-r fix-gradient from-yellow-500 via-yellow-700 to-yellow-500 bg-pos-0 bg-size-200 text-white hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-yellow-500 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 disabled:text-gray-400',
  accent:
    'text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500 disabled:bg-gray-200 disabled:text-gray-400'
}
