import DiscordIcon from '@/components/svg/solid/DiscordIcon.vue'
import DocsIcon from '@/components/svg/solid/DocsIcon.vue'
// import GithubIcon from '@/components/svg/solid/GithubIcon.vue'
import MediumIcon from '@/components/svg/solid/MediumIcon.vue'
import TwitterIcon from '@/components/svg/solid/TwitterIcon.vue'
import { isTestnet } from './defaults'

const internalLinks = [
  {
    text: 'Market',
    to: { name: 'Market' }
  }
]

if (!isTestnet) {
  internalLinks.push({
    text: 'Rewards',
    to: { name: 'Rewards' }
  })
}

export const INTERNAL_LINKS = internalLinks

export const EXTERNAL_LINKS = [
  {
    icon: DocsIcon,
    text: 'Docs',
    href: 'https://docs.aurigami.finance'
  },
  {
    icon: TwitterIcon,
    text: 'Twitter',
    href: 'https://twitter.com/aurigami_PLY'
  },
  {
    icon: DiscordIcon,
    text: 'Discord',
    href: 'https://discord.gg/MpgHRtm8EK'
  },
  {
    icon: MediumIcon,
    text: 'Medium',
    href: 'https://medium.com/aurigami'
  }
  // {
  //   icon: RainbowBridge,
  //   text: 'Bridge',
  //   href: 'https://rainbowbridge.app/transfer'
  // }
  // {
  //   icon: GithubIcon,
  //   text: 'GitHub',
  //   href: 'https://github.com/Aurigami-Finance'
  // }
]

export default {
  INTERNAL_LINKS,
  EXTERNAL_LINKS
}
