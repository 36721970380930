import images from '@/assets/images/tokens'
import addresses from '@/app-config/addresses/aurora/tokens'

export default [
  {
    name: 'Aurora',
    symbol: 'AURORA',
    address: addresses.AURORA,
    img: images.aurora,
    decimals: 18,
    ignoreApproval: []
  },
  {
    name: 'Wrapped NEAR',
    symbol: 'WNEAR',
    address: addresses.WNEAR,
    img: images.wnear,
    decimals: 24,
    ignoreApproval: []
  },
  {
    name: 'USDC',
    symbol: 'USDC',
    address: addresses.USDC,
    img: images.usdc,
    decimals: 6,
    ignoreApproval: []
  },
  {
    name: 'USDT',
    symbol: 'USDT',
    address: addresses.USDT,
    img: images.usdt,
    decimals: 6,
    ignoreApproval: []
  },
  // {
  //   name: 'Dai',
  //   symbol: 'DAI',
  //   address: addresses.DAI,
  //   img: images.dai,
  //   decimals: 18,
  //   ignoreApproval: []
  // },
  {
    name: 'Ether',
    symbol: 'ETH',
    address: addresses.ETH,
    img: images.eth,
    decimals: 18,
    ignoreApproval: ['all']
  },
  {
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    address: addresses.WBTC,
    img: images.wbtc,
    decimals: 8,
    ignoreApproval: []
  },
  {
    name: 'Ply',
    symbol: 'PLY',
    address: addresses.PLY,
    img: images.ply,
    decimals: 18,
    ignoreApproval: []
  }
]
