import { isTestnet } from '@/ui-config/defaults'
import * as Mainnet from 'sdk-mainnet'
import * as Testnet from 'sdk-testnet'

export default isTestnet ? Testnet : Mainnet
export const SdkRead = isTestnet ? Testnet.SdkRead : Mainnet.SdkRead
export const SdkReadWrite = isTestnet ? Testnet.SdkReadWrite : Mainnet.SdkReadWrite
export const MoneyMarket = isTestnet ? Testnet.MoneyMarket : Mainnet.MoneyMarket
export const MoneyMarketReadWrite = isTestnet ? Testnet.MoneyMarketReadWrite : Mainnet.MoneyMarketReadWrite
export const MarketAction = isTestnet ? Testnet.MarketAction : Mainnet.MarketAction
