<script setup lang="ts">
import { computed } from 'vue'
import type { TabColor } from '@/@types/TabColor'
import type { TabVariant } from '@/@types/TabVariant'
import type { RouteLocationRaw } from 'vue-router'
import TabColors from '@/ui-config/tab-colors'
import ButtonSizes from '@/ui-config/button-sizes'

const props = withDefaults(
  defineProps<{
    to?: RouteLocationRaw,
    color?: TabColor,
    variant?: TabVariant,
    exact?: boolean,
    size?: keyof typeof ButtonSizes,
    external?: string
  }>(),
  {
    external: undefined,
    color: 'brand',
    variant: 'bold',
    exact: false,
    size: 'md'
  }
)

const selectedVariant = computed(() => TabColors[props.color][props.variant])
const activeClassnames = computed(() => selectedVariant.value.active)
</script>

<template>
  <a
    v-if="external"
    class="pp-activable"
    :href="external"
    target="_blank"
    v-bind="$attrs"
    :class="[
      selectedVariant.inactive,
      selectedVariant.base,
      ButtonSizes[size]
    ]"
  >
    <slot :accent-class="activeClassnames.secondary"></slot>
  </a>

  <router-link v-else :to="to!" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
    <a
      class="pp-activable"
      @click="navigate"
      :href="href"
      :aria-current="isExactActive ? 'page' : undefined"
      v-bind="$attrs"
      :class="[
        isActive || isExactActive
          ? activeClassnames.primary
          : selectedVariant.inactive,
        selectedVariant.base,
        ButtonSizes[size]
      ]"
    >
      <slot
        :route="route"
        :accent-class="activeClassnames.secondary"
        :active="exact ? isActive : isExactActive"
      ></slot>
    </a>
  </router-link>
</template>
