import notifier from '@/domains/NotificationContainer'
import waitForConfirmation from './wait-for-confirmation'
import type { TransactionResponse } from '@ethersproject/abstract-provider'

const handleTransaction = (response: TransactionResponse, submitted: string, success: { title: string, text: string }, { onProgress, onConfirmed }: { onProgress?: () => void, onConfirmed?: () => void}) => {
  let notification: any
  let resolved: boolean

  return new Promise((resolve, reject) => {
    waitForConfirmation(
      response,
      {
        onProgress: () => {
          if (!resolved) {
            resolved = true
            resolve(response)
          }

          if (!notification) {
            notification = notifier.success({
              title: submitted,
              text: 'Waiting for confirmation',
              action: {
                text: 'View transaction',
                url: `${import.meta.env.VITE_AURORA_BLOCK_EXPLORER}tx/${response.hash}`
              },
              status: 'pending'
            })
          }

          onProgress?.()
        },
        onConfirmed: () => {
          const notif = notifier.notifications.value.find(not => not.id === notification.id)!
          notif.status = 'completed'
          notif.title = success.title
          notif.text = success.text
          onConfirmed?.()
        }
      }
    ).catch(error => {
      reject(error)
    })
  })
}
export default handleTransaction
