<script setup lang="ts">
import { ref, computed, RenderFunction, watch } from 'vue'
import type { AppNotification, NotificationType } from '@/@types/Notification'
import type { ButtonColor } from '@/@types/ButtonColor'
import { useGlobalStore } from '@/pinia/global'
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, XIcon } from '@heroicons/vue/outline'
import PpSpinner from '@/components/global/PpSpinner.vue'

type ToastStyle = {
  as: RenderFunction
  class: string
  color: ButtonColor
}
const global = useGlobalStore()

const props = defineProps<{
  notification: AppNotification
}>()

const styles: Record<NotificationType, ToastStyle> & { pending: ToastStyle } = {
  info: {
    as: InformationCircleIcon,
    class: 'bg-blue-700',
    color: 'blue'
  },
  success: {
    as: CheckCircleIcon,
    class: 'bg-green-700',
    color: 'green'
  },
  error: {
    as: XCircleIcon,
    class: 'bg-red-700',
    color: 'red'
  },
  pending: {
    as: PpSpinner,
    class: 'bg-yellow-700',
    color: 'yellow'
  }
}

const TOAST_DISPLAY_DURATION = 5000
const hide = ref(false)
// @ts-ignore
const toastStyle = computed(() => styles[props.notification.status === 'pending' ? 'pending' : props.notification.type])
const show = (global.time.getTime() - new Date(props.notification.createdAt).getTime()) < TOAST_DISPLAY_DURATION

let timeout: undefined | number
watch(() => props.notification.status, (newVal) => {
  clearTimeout(timeout)
  if (newVal !== 'pending') {
    timeout = window.setTimeout(() => {
      hide.value = true
    }, TOAST_DISPLAY_DURATION)
  }
}, { immediate: true })

const computedShow = computed(() => show && !hide.value)
</script>

<template>
  <div
    v-show="computedShow"
    class="w-full max-w-sm overflow-hidden text-white transition duration-500 rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
    :class="toastStyle.class"
  >
    <div v-if="notification.status !== 'pending'" class="h-1 bg-gray-900/30">
      <div class="h-full transform -translate-x-full rounded-full bg-gray-900/40 countdown-bar"></div>
    </div>

    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <component :is="toastStyle.as" class="w-6 h-6" aria-hidden="true" />
        </div>

        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-white">{{ notification.title }}</p>
          <p class="mt-1 text-sm text-gray-100">{{ notification.text }}</p>

          <div v-if="notification.action" class="mt-4">
            <pp-btn
              as="a"
              size="sm"
              variant="text"
              class="-ml-2 !text-white hover:!bg-gray-900/40"
              :color="toastStyle.color"
              :href="notification.action.url"
              target="_blank"
            >{{ notification.action.text }}</pp-btn>
          </div>
        </div>

        <div class="flex flex-shrink-0 ml-4">
          <pp-btn
            icon
            variant="text"
            class="rounded-full !text-white hover:!bg-gray-900/40 focus:ring-gray-100"
            @click="hide = true"
          >
            <span class="sr-only">Close</span>
            <XIcon class="w-5 h-5" aria-hidden="true" />
          </pp-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes moveLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.countdown-bar {
  animation: moveLeft 5000ms linear;
}
</style>
