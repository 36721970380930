export default {
  fill: 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 disabled:text-gray-400 disabled:bg-gray-200',
  text: 'text-red-200 shadow-none hover:bg-red-100 hover:text-red-800 focus:ring-red-500 disabled:text-gray-400 disabled:bg-transparent',
  outline:
    'border border-red-800 text-red-800 hover:bg-red-100 focus:ring-red-500 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent',
  gradient:
    'bg-gradient-to-r fix-gradient from-red-500 via-red-700 to-red-500 bg-pos-0 bg-size-200 text-white hover:bg-pos-100 focus:bg-pos-100 group-hover:bg-pos-100 focus:ring-red-500 disabled:from-gray-200 disabled:via-gray-200 disabled:to-gray-200 disabled:text-gray-400',
  accent:
    'text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500 disabled:bg-gray-200 disabled:text-gray-400'
}
