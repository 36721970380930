<script setup lang="ts">
import { UserMarketDetails } from '@/@types/UserMarketDetails'
import { ref } from 'vue'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import MoneyMarketCard from '@/components/MoneyMarketCard.vue'

defineProps<{
  position: UserMarketDetails
}>()

const openRepay = ref(false)
const successCount = ref(0)
const chainConnection = useChainConnectionStore()
const handleSuccess = () => {
  openRepay.value = false
  successCount.value++
}
</script>

<template>
  <pp-tr>
    <pp-td valign="middle">
      <div class="flex items-center space-x-2">
        <pp-token-img :token="position.market.assetToken" class="inline-block w-6 h-6"></pp-token-img>
        <pp-token-symbol :token="position.market.assetToken"></pp-token-symbol>
      </div>
    </pp-td>
    <pp-td align="right" valign="middle">
      <pp-valuation :valuation="position.borrowBalance.valuation"></pp-valuation>

      <div class="text-xs text-gray-300">
        <pp-token-amount :token-amount="position.borrowBalance"></pp-token-amount>
      </div>
    </pp-td>
    <pp-td align="right" valign="middle">
      <pp-modal v-model="openRepay">
        <template v-slot:activator="{ on }">
          <pp-btn size="sm" v-bind="on">Repay</pp-btn>
        </template>

        <MoneyMarketCard
          page="Repay"
          :market="position.market"
          :user="chainConnection.user!"
          @success="handleSuccess"
          :key="successCount"
        />
      </pp-modal>
    </pp-td>
  </pp-tr>
</template>
