<template>
  <nav class="flex items-center p-4 space-x-3 shadow">
    <router-link to="/" class="pp-activable focus:ring-brand-500">
      <pp-lockup class="h-8"></pp-lockup>
    </router-link>

    <div class="!ml-10 flex space-x-2 items-center">
      <pp-nav-link
        v-for="link in internalLinks"
        :key="link.text"
        :to="link.to"
        variant="outline"
        color="white"
      >{{ link.text }}</pp-nav-link>

      <pp-nav-link
        key="static"
        external="https://rainbowbridge.app/transfer"
        variant="outline"
        color="white"
      >
        <RainbowBridge class="w-5" />

        <div class="ml-2">
          <span class="align-middle">Bridge</span>
        </div>
      </pp-nav-link>
    </div>

    <div class="flex-grow"></div>

    <div class="flex items-center space-x-2">
      <FaucetBtn size="sm" />
      <pp-btn
        @click="() => chainConnection.isConnected ? chainConnection.disconnectWallet() : chainConnection.connectWallet()"
        class="bg-gradient-to-l hover:gradient-jade gradient-silk !bg-transparent fix-gradient"
        size="sm"
      >
        <pp-wallet-address
          v-if="chainConnection.isConnected"
          class="lowercase"
          :address="(chainConnection.walletAddress as string)"
        ></pp-wallet-address>
        <span v-else>Connect Wallet</span>
      </pp-btn>

      <pp-dropdown icon :options="externalLinkMetadatas" variant="highlight" color="white">
        <template v-slot:activator-content>
          <DotsHorizontalIcon />
        </template>

        <template v-slot:option="{ option }">
          <div>
            <component :is="option.icon" class="w-5 h-5"></component>
          </div>

          <div class="ml-3">
            <span class="align-middle">{{ option.text }}</span>
          </div>
        </template>
      </pp-dropdown>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { DotsHorizontalIcon } from '@heroicons/vue/outline'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import FaucetBtn from '../FaucetBtn.vue'
import RainbowBridge from '@/components/svg/solid/RainbowBridge.vue'

type InternalLink = {
  text: string,
  to: RouteLocationRaw
}

type ExternalLink = {
  text: string,
  href: string,
  icon?: unknown
}

const props = defineProps<{
  internalLinks: Array<InternalLink>
  externalLinks: Array<ExternalLink>
}>()

const chainConnection = useChainConnectionStore()

const externalLinkMetadatas = computed(() => props.externalLinks.map(link => ({ ...link, as: 'a', attrs: { href: link.href, target: '_blank' } })))
</script>
