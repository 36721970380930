import { OnboardWallet } from '@/@types/OnboardWallet'

export const APP_NAME = import.meta.env.VITE_APP_NAME

export const NETWORK_ID = parseInt(import.meta.env.VITE_CHAIN_ID as string)
export const RPC_URLS = [import.meta.env.VITE_AURORA_RPC_URL as string]
export const RPC_URL = RPC_URLS[Math.floor(Math.random() * RPC_URLS.length)]
export const isTestnet = import.meta.env.VITE_NETWORK_NAME?.toString().toLowerCase().includes('testnet')
export const MAX_MARKET_POSITIONS = 7

window.info(`Session RPC URL: ${RPC_URL}`)

export const ONBOARD_WALLET_CONFIGS: OnboardWallet[] = [
  { walletName: 'metamask', preferred: true },
  { walletName: 'imToken', rpcUrl: RPC_URL, preferred: true },
  { walletName: 'trust', rpcUrl: RPC_URL, preferred: true },
  // {
  //   walletName: 'walletConnect',
  //   rpc: { [NETWORK_ID]: RPC_URL },
  //   preferred: true
  // },
  { walletName: 'coinbase', preferred: true },
  { walletName: 'walletLink', rpcUrl: RPC_URL, preferred: true }
]

export const BLOCKNATIVE_API_KEY = ''

/**
 * Number of confirmed blocks we want to wait for before we consider a transaction successful
 */
export const BLOCK_CONFIRMATION_COUNT = 6

/**
 * The localStorage key for caching user's selected wallet
 */
export const ONBOARD_SELECTED_WALLET_KEY = 'selectedWallet'
