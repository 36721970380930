// https://doc.aurora.dev/getting-started/network-endpoints/

export const AURORA_PARAMS = {
  chainId: import.meta.env.VITE_HEXED_CHAIN_ID,
  chainName: import.meta.env.VITE_NETWORK_NAME,
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  rpcUrls: [import.meta.env.VITE_METAMASK_AURORA_RPC_URL],
  blockExplorerUrls: [import.meta.env.VITE_AURORA_BLOCK_EXPLORER]
}
