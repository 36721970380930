<script setup lang="ts">
const wrapConfig = {
  nowrap: '',
  auto: 'flex-wrap',
  xs: 'flex-col',
  sm: 'sm:flex-row flex-col',
  md: 'md:flex-row flex-col',
  lg: 'lg:flex-row flex-col',
  xl: 'xl:flex-row flex-col'
}

withDefaults(
  defineProps<{
    left?: any
    right?: any
    leftClass?: string
    rightClass?: string
    wrap?: keyof typeof wrapConfig
  }>(),
  {
    leftClass: '',
    rightClass: '',
    wrap: 'nowrap'
  }
)
</script>

<template>
  <div class="flex" :class="wrapConfig[wrap as keyof typeof wrapConfig]">
    <div :class="leftClass">
      <slot name="left">{{ left }}</slot>
    </div>

    <div class="flex-grow ml-4"></div>

    <div :class="rightClass">
      <slot name="right">{{ right }}</slot>
    </div>
  </div>
</template>
