<script setup lang="ts">
import { reactive, watch } from 'vue'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import TokenAmount from '@/domains/TokenAmount'
import Valuation from '@/domains/Valuation'

const props = defineProps<{
  tokenAmount: TokenAmount
}>()

const valuationState = reactive(createState({ response: new Valuation({ currency: 'USD', amount: 0 }) }))

const valuationFetcher = new PromiseHandler(
  () => props.tokenAmount.token.valuation(),
  valuationState
)

watch(() => props.tokenAmount, () => {
  valuationFetcher.execute()
}, { immediate: true })
</script>

<template>
  <div>
    <pp-valuation v-if="valuationState.is('resolved-once')" :valuation="tokenAmount.valuation"></pp-valuation>
    <pp-skeleton-loader v-else-if="valuationState.is('first-load')" class="w-20"></pp-skeleton-loader>
    <pp-btn
      v-else-if="valuationState.is('rejected')"
      color="red"
      size="xs"
      variant="text"
      @click="valuationFetcher.execute()"
    >Retry loading</pp-btn>
  </div>
</template>
