<script setup lang="ts">
import BigNumber from 'bignumber.js'
import { ArrowSmRightIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    lowerBetter?: boolean
    width?: string
    before: string | number
    after: string | number
  }>(),
  {
    width: '7rem',
    lowerBetter: false
  }
)

const getBn = (num: string | number) => new BigNumber(
  num.toString()
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1') || 0
)

const positive = computed(() => {
  const before = getBn(props.before)
  const after = getBn(props.after)

  return props.lowerBetter ? after.lte(before) : after.gte(before)
})
</script>

<template>
  <div class="inline-flex items-center space-x-1 whitespace-nowrap">
    <span class="inline-block text-left truncate" :style="{ width }">
      <slot name="before" :value="before">{{ before }}</slot>
    </span>
    <ArrowSmRightIcon class="w-5 h-5" />
    <span
      :class="positive ? 'text-green-500' : 'text-red-500'"
      :style="{ width }"
      class="inline-block text-right truncate"
    >
      <slot name="after" :value="after">{{ after }}</slot>
    </span>
  </div>
</template>
