<script setup lang="ts">
withDefaults(
  defineProps<{
    as?: string,
    align?: 'left' | 'right' | 'center',
    valign?: 'top' | 'middle' | 'bottom'
  }>(),
  {
    as: 'td',
    align: 'left',
    valign: 'top'
  }
)

const alignMap = {
  center: 'text-center',
  right: 'text-right',
  left: 'text-left'
}

const vAlignMap = {
  top: 'align-top',
  middle: 'align-middle',
  bottom: 'align-bottom'
}
</script>

<template>
  <component :is="as" class="pp-td" :class="[alignMap[align], vAlignMap[valign]]">
    <slot></slot>
  </component>
</template>
