<script setup lang="ts">
import { computed } from 'vue'
import BigNumber from 'bignumber.js'

const props = withDefaults(
  defineProps<{
    value: number | string
    minDecimals?: number
    maxDecimals?: number
  }>(),
  {
    minDecimals: 2,
    maxDecimals: 2
  }
)

const num = computed(() => new BigNumber(props.value).multipliedBy(100).toFixed())
</script>

<template>
  <span>
    <pp-formatted-number
      :value="num"
      :min-decimals="minDecimals"
      :max-decimals="maxDecimals"
      notation="compact"
    ></pp-formatted-number>%
  </span>
</template>
