export default class NullPromise {
  then(_cb: (args: unknown) => unknown): NullPromise {
    return this // noop
  }

  catch(): NullPromise {
    return this // noop
  }

  finally(): NullPromise {
    return this // noop
  }
}
