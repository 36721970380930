<script setup lang="ts">
withDefaults(
  defineProps<{
    filler?: boolean,
    align?: 'left' | 'right' | 'center',
    valign?: 'top' | 'middle' | 'bottom'
  }>(),
  {
    filler: false,
    align: 'left',
    valign: 'top'
  }
)

const alignMap = {
  center: 'text-center',
  right: 'text-right',
  left: 'text-left'
}

const vAlignMap = {
  top: 'align-top',
  middle: 'align-middle',
  bottom: 'align-bottom'
}
</script>

<template>
  <th
    class="pp-th"
    :class="[filler ? 'p-0' : '', alignMap[align], vAlignMap[valign]]"
    v-bind="$attrs"
  >
    <slot></slot>
  </th>
</template>
