export default {
  fill: {
    base:
      'rounded-md focus:ring-white disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'bg-white text-white',
      secondary: 'text-white'
    },
    inactive: 'text-gray-500 hover:text-gray-700'
  },
  highlight: {
    base: 'rounded-md focus:ring-white disabled:text-gray-400',
    active: {
      primary: 'bg-white bg-opacity-30 text-white',
      secondary: 'text-white'
    },
    inactive: 'text-white opacity-80 hover:opacity-100'
  },
  bold: {
    base: 'focus:ring-white disabled:text-gray-500 disabled:bg-gray-200',
    active: {
      primary: 'text-white',
      secondary: 'text-white'
    },
    inactive: 'text-gray-400 hover:text-gray-100'
  },
  outline: {
    base:
      'text-white opacity-80 !ring-0 focus:bg-white focus:bg-opacity-20 disabled:text-gray-400 disabled:bg-gray-200',
    active: {
      primary: 'font-semibold border-2 border-brand-500 !opacity-100',
      secondary: 'text-white'
    },
    inactive: 'text-white hover:opacity-100'
  }
}
