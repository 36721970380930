<script setup lang="ts">
import { getCurrentInstance, computed } from 'vue'
import TokenAmount from '@/domains/TokenAmount'
import Token from '@/domains/Token'
import type { SelectOption } from '@/@types/SelectOption'
import type { HTMLInputMode } from '@/@types/HTMLInputMode'

const props = defineProps<{
  modelValue: TokenAmount
  tokenOptions?: Token[]
  disabled?: boolean
  placeholder?: string
  autocomplete?: 'on' | 'off'
  readonly?: boolean
  inputmode?: HTMLInputMode
  type?: string
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: TokenAmount): void
}>()

const instance = getCurrentInstance()
const id = `pp-token-amount-input-${instance?.uid}`
const mappedOptions = computed(() => props.tokenOptions?.map(token => ({
  id: token.address,
  text: token.name,
  img: { src: token.img, alt: `${token.name} icon` },
  token
})) || [])

const selectedOption = computed(() => mappedOptions.value.find(option => option.id === props.modelValue.token.address))

const handleAmountInput = (amount: string) => {
  emit('update:modelValue', new TokenAmount(props.modelValue.token, amount, false))
}
const handleTokenInput = (token: Token) => {
  emit('update:modelValue', new TokenAmount(token, props.modelValue.formattedAmount, false))
}
</script>

<template>
  <div class="flex items-center">
    <pp-number-input
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :type="type"
      :inputmode="inputmode"
      :model-value="modelValue.formattedAmount"
      :max-decimal="modelValue.token.decimals"
      @update:model-value="handleAmountInput($event)"
      class="pl-2 pr-1 py-1 border-0 focus:outline-none min-w-auto transition focus:ring-2 focus:ring-brand-500 rounded"
    ></pp-number-input>

    <div class="pr-2 pl-1 py-1">
      <pp-select
        v-if="tokenOptions"
        :options="mappedOptions"
        :model-value="selectedOption"
        @update:model-value="handleTokenInput(($event as SelectOption<{ token: Token }>).token)"
      ></pp-select>
      <label v-else :for="id">{{ modelValue.token.symbol }}</label>
    </div>
  </div>
</template>
