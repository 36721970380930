import type { ChainIdentity } from '@/@types/ChainIdentity'
import type { TokenConstructor } from '@/@types/TokenConstructor'
import TokenContract from '@/domains/contracts/TokenContract'
import TokenCollection from './collections/TokenCollection'
import Tokens from '@/app-config/tokens'
import TokenValuationStore from '@/domains/TokenValuationStore'

export default class Token {
  public address: string
  public decimals: number
  public name: string
  public symbol: string
  public img: string
  public protocol: string
  public ignoreApproval: string[]
  public chainId: number
  static readonly tokens = Tokens.map(token => new Token(token))

  constructor({
    decimals,
    name,
    symbol,
    img,
    protocol,
    address,
    ignoreApproval,
    chainId
  }: TokenConstructor) {
    this.address = address
    this.decimals = decimals
    this.name = name
    this.symbol = symbol
    this.img = img
    this.protocol = protocol
    this.ignoreApproval = ignoreApproval
    this.chainId = chainId
  }

  static query() {
    return TokenCollection.all()
  }

  contract({ signer, provider }: ChainIdentity) {
    return new TokenContract({ token: this, provider, signer })
  }

  valuation() {
    return TokenValuationStore.findOrFetchValuation({ token: this })
  }

  get readValuation() {
    return TokenValuationStore.readTokenValuation(this)
  }

  get hasFetchedValuation() {
    return TokenValuationStore.hasFetchedValuation(this)
  }
}
