<script setup lang="ts">
import DepositsSection from '@/components/my-account/DepositsSection.vue'
import BorrowsSection from '@/components/my-account/BorrowsSection.vue'
import StatusSection from '@/components/my-account/StatusSection.vue'
import { ref } from 'vue'
import User from '@/domains/User'
import { isTestnet } from '@/ui-config/defaults'

const props = defineProps<{
  user: User
}>()

const getDetails = () => props.user.getDetails()
getDetails()

const tabs = ['Deposits', 'Borrows', isTestnet ? 'Status' : 'Rewards']
const activeTab = ref(0)
</script>

<template>
  <pp-card>
    <pp-card-title>My Account</pp-card-title>

    <pp-card-body class="overflow-visible">
      <pp-alert
        v-if="user.details.is('rejected')"
        variant="error"
        header="Failed to load user data"
        content="Retry again in a few minutes"
        :actions="[{ text: 'Retry', action: getDetails }]"
      ></pp-alert>
      <pp-card-body v-else class="space-y-6 overflow-visible">
        <pp-stat header="Net Balance" size="lg">
          <template #value>
            <pp-valuation v-if="user.details.is('resolved')" :valuation="user.status.netBalance"></pp-valuation>
            <pp-skeleton-loader class="w-32 h-8" v-else-if="user.details.is('loading')"></pp-skeleton-loader>
          </template>
        </pp-stat>

        <div class="flex flex-col justify-between mt-4 sm:flex-row">
          <pp-stat header="Deposited balance" value="$474,862.43">
            <template #value>
              <pp-valuation
                v-if="user.details.is('resolved')"
                :valuation="user.status.depositedBalance"
              ></pp-valuation>
              <pp-skeleton-loader class="h-6" v-else-if="user.details.is('loading')"></pp-skeleton-loader>
            </template>
          </pp-stat>
          <pp-stat header="Borrowed balance" class="sm:text-right">
            <template #value>
              <pp-valuation
                v-if="user.details.is('resolved')"
                :valuation="user.status.borrowedBalance"
              ></pp-valuation>
              <pp-skeleton-loader class="h-6" v-else-if="user.details.is('loading')"></pp-skeleton-loader>
            </template>
          </pp-stat>
        </div>

        <div>
          <div class="text-gray-300">Borrow utilisation</div>
          <pp-progress-bar
            static-label
            v-if="user.details.is('resolved')"
            :value="user.status.borrowUtilisation"
          ></pp-progress-bar>

          <pp-skeleton-loader v-else-if="user.details.is('loading')" class="w-full h-3"></pp-skeleton-loader>
        </div>

        <pp-left-right>
          <template #left>Borrow limit</template>
          <template #right>
            <pp-valuation v-if="user.details.is('resolved')" :valuation="user.status.borrowLimit"></pp-valuation>
            <pp-skeleton-loader v-else-if="user.details.is('loading')"></pp-skeleton-loader>
          </template>
        </pp-left-right>
      </pp-card-body>
    </pp-card-body>

    <pp-card-body v-if="user.details.is('loading')" class="space-y-3">
      <pp-skeleton-loader v-for="n in 2" :key="n" class="w-full h-10"></pp-skeleton-loader>
    </pp-card-body>
    <pp-card-body v-else-if="user.details.is('resolved')" class="!px-1">
      <pp-tab-section :tabs="tabs" v-model="activeTab">
        <transition
          appear
          enter-active-class="transition duration-300 ease-out"
          enter-from-class="-translate-y-5 opacity-0"
        >
          <DepositsSection :market-positions="user.status.marketPositions" />
        </transition>

        <transition
          appear
          enter-active-class="transition duration-300 ease-out"
          enter-from-class="-translate-y-5 opacity-0"
        >
          <BorrowsSection :market-positions="user.status.marketPositions" />
        </transition>

        <transition
          appear
          enter-active-class="transition duration-300 ease-out"
          enter-from-class="-translate-y-5 opacity-0"
        >
          <StatusSection v-if="isTestnet" :market-positions="user.status.marketPositions" />
          <div v-else class="px-2">
            <p class="text-gray-50">Coming soon.</p>
          </div>
        </transition>
      </pp-tab-section>
    </pp-card-body>
  </pp-card>
</template>
