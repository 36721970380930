<script setup lang="ts">
import { onBeforeUnmount } from 'vue'
withDefaults(
  defineProps<{
    modelValue?: boolean,
    width?: string | number
  }>(),
  { modelValue: false, width: 500 }
)

const emit = defineEmits<{
  (event: 'update:modelValue', isOpen: boolean): void
}>()
const closeModal = () => emit('update:modelValue', false)

onBeforeUnmount(() => closeModal())
</script>

<template>
  <slot name="activator" :on="{ onClick: () => emit('update:modelValue', true) }"></slot>

  <pp-transition-root appear :show="modelValue" as="template">
    <pp-dialog as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <pp-transition-child
            as="template"
            enter="duration-150 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <PpDialogOverlay class="fixed inset-0 bg-black bg-opacity-30" />
          </pp-transition-child>

          <span class="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

          <pp-transition-child
            as="template"
            enter="duration-100 ease-in"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block max-w-full px-1 pt-1 pb-2 overflow-hidden text-left align-middle transition transform pt-q pp-dialog-container"
              :style="{ width: `${width}px` }"
            >
              <slot :close="closeModal"></slot>
            </div>
          </pp-transition-child>
        </div>
      </div>
    </pp-dialog>
  </pp-transition-root>
</template>
