<script setup lang="ts">
import MoneyMarket from '@/domains/MoneyMarket'
import PromiseHandler, { createState } from '@/domains/PromiseHandler'
import TokenAmount from '@/domains/TokenAmount'
import User from '@/domains/User'
import { ref, computed, reactive, getCurrentInstance } from 'vue'
import { useChainConnectionStore } from '@/pinia/chain-connection'
import { UserMarketDetails } from '@/@types/UserMarketDetails'
import notifier from '@/domains/NotificationContainer'

const uid = getCurrentInstance()?.uid
const inputId = `pp-input-${uid}`

const props = defineProps<{
  market: MoneyMarket
  user: User
  position: UserMarketDetails
}>()

const emit = defineEmits<{
  (event: 'success'): void
}>()

const chainConnection = useChainConnectionStore()
const amount = ref('')
const submitAmount = computed(() => isNaN(parseFloat(amount.value)) ? 0 : amount.value)

const submitTokenAmount = computed(
  () => {
    return new TokenAmount(
      props.market.assetToken,
      submitAmount.value,
      false
    )
  }
)

const actualSubmitTokenAmount = computed(
  () => {
    return new TokenAmount(
      props.market.assetToken,
      isMax.value ? -1 : submitAmount.value,
      isMax.value
    )
  }
)

const handleInput = (newVal: string) => {
  amount.value = newVal
}

const newStats = computed(
  () => props.market.contract(props.user)
    .calcStatChanges(props.user.status, 'withdraw', submitTokenAmount.value)
)

const formHandler = new PromiseHandler(
  () => {
    return props.market.contract(chainConnection.identity).withdraw(actualSubmitTokenAmount.value)
  },
  reactive(createState())
)

const withdrawableAmount = computed(() => {
  if (props.position.isCollateral) {
    if (props.position.maxWithdrawableAmount.eq(props.position.depositBalance)) {
      return props.position.depositBalance.formattedAmount
    } else {
      return props.position.maxWithdrawableAmount.formattedAmount
    }
  } else {
    return props.position.depositBalance.formattedAmount
  }
})

const isMax = computed(() =>
  props.position.isCollateral
    ? props.position.maxWithdrawableAmount.eq(props.position.depositBalance) && props.position.maxWithdrawableAmount.eq(submitTokenAmount.value)
    : props.position.depositBalance.formattedAmount === submitTokenAmount.value.formattedAmount
)

const handleMax = () => {
  amount.value = withdrawableAmount.value
}

const showStats = computed(() => props.position.depositBalance.gt0() && props.position.isCollateral)
</script>

<template>
  <form
    @submit.prevent="formHandler.execute({
      success: () => {
        emit('success')
      },
      error: () => {
        notifier.error({ title: `Unable to withdraw ${submitTokenAmount.token.symbol}`, text: 'Please try again in a few minutes' })
      }
    })"
  >
    <pp-card class="!shadow-none">
      <pp-card-body class="px-0 md:px-4">
        <div class="flex items-center">
          <div
            class="inline-flex items-center px-1 py-1 text-2xl rounded-lg flex-grow-1 bg-white/10"
          >
            <pp-number-input
              :id="inputId"
              class="w-full text-2xl text-right"
              placeholder="Amount"
              :model-value="amount"
              @update:model-value="handleInput"
              :max-decimal="market.assetToken.decimals"
              autocomplete="off"
            ></pp-number-input>

            <label :for="inputId">
              <pp-token-symbol class="px-2" :token="market.assetToken"></pp-token-symbol>
            </label>
          </div>

          <div class="flex-shrink ml-4">
            <pp-btn @click="handleMax()" size="sm" class="rounded-full">Max</pp-btn>
          </div>
        </div>

        <div
          v-if="!isMax && submitTokenAmount.gt(position.depositBalance)"
          class="mt-2 text-sm font-medium text-red-400"
        >Amount is over your deposit amount.</div>

        <div class="mt-2 text-sm text-center text-gray-300">
          <pp-async-token-amount-valuation :token-amount="submitTokenAmount"></pp-async-token-amount-valuation>
        </div>

        <div class="mt-10 space-y-5">
          <template v-if="amount !== '' && showStats">
            <pp-left-right wrap="sm">
              <template #left>
                <div class="text-gray-300">Borrow limit</div>
              </template>
              <template #right>
                <pp-before-after
                  :before="user.status.borrowLimit.format()"
                  :after="newStats.newBorrowLimit.format()"
                ></pp-before-after>
              </template>
            </pp-left-right>

            <pp-left-right wrap="sm">
              <template #left>
                <div class="text-gray-300">Borrow utilisation</div>
              </template>
              <template #right>
                <pp-before-after
                  lower-better
                  :before="user.status.borrowUtilisation"
                  :after="newStats.newBorrowUtilisation"
                >
                  <template #before="{ value }">
                    <pp-percent :value="value"></pp-percent>
                  </template>
                  <template #after="{ value }">
                    <pp-percent v-if="isNaN(value)" class="text-gray-50" :value="0"></pp-percent>
                    <pp-percent
                      v-else
                      :class="value >= 0.9 ? 'text-red-500' : value >= 0.6 ? 'text-yellow-500' : 'text-green-500'"
                      :value="value"
                    ></pp-percent>
                  </template>
                </pp-before-after>
              </template>
            </pp-left-right>
          </template>

          <pp-left-right wrap="sm">
            <template #left>
              <div class="text-gray-300">Deposit APY</div>
            </template>
            <template #right>
              <pp-percent :value="market.details.response.depositApy"></pp-percent>
            </template>
          </pp-left-right>
        </div>
      </pp-card-body>

      <pp-card-actions class="mt-3">
        <pp-btn
          :loading="formHandler.state.is('loading')"
          :disabled="parseFloat(submitAmount.toString()) <= 0 || submitTokenAmount.gt(position.maxWithdrawableAmount)"
          class="w-full rounded-full"
          type="submit"
          size="lg"
        >Withdraw</pp-btn>
      </pp-card-actions>
    </pp-card>
  </form>
</template>
