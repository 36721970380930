import { reactive } from 'vue'
import Valuation from '@/domains/Valuation'
import Token from './Token'
import { SdkRead } from '@/plugins/sdk'
import { ethers } from 'ethers'
import { RPC_URL } from '@/ui-config/defaults'

const minute = 1000 * 60
const CACHE_TTL = 3 * minute

type TokenPriceStore = {
  [address: string]: {
    valuation: Valuation
    updatedAt: Date
  }
}

type TokenValuationFetcher = { token: Token }

class TokenValuationStore {
  public tokenPrices

  constructor() {
    this.tokenPrices = reactive<Record<number, TokenPriceStore>>({})
  }

  async findOrFetchValuation({ token }: TokenValuationFetcher) {
    this.tokenPrices[token.chainId] = this.tokenPrices[token.chainId] || {}
    const valuationMeta = this.tokenPrices[token.chainId][token.address]

    if (valuationMeta) {
      const hasExpired =
        new Date().getTime() - valuationMeta.updatedAt.getTime() > CACHE_TTL

      if (hasExpired) {
        try {
          return await this.fetchTokenValuation({ token })
        } catch {
          return valuationMeta.valuation
        }
      } else {
        return valuationMeta.valuation
      }
    } else {
      return await this.fetchTokenValuation({ token })
    }
  }

  readTokenValuation(token: Token) {
    const defaultValuation = new Valuation({ currency: 'USD', amount: 0 })

    return this.tokenPrices[token.chainId]?.[token.address]?.valuation || defaultValuation
  }

  hasFetchedValuation(token: Token) {
    return !!(this.tokenPrices[token.chainId]?.[token.address]?.valuation)
  }

  async fetchTokenValuation({ token }: TokenValuationFetcher) {
    const sdkValuation = await new SdkRead({ provider: ethers.getDefaultProvider(RPC_URL) }).getTokenPrice(token)
    const valuation = Valuation.fromSdk(sdkValuation)

    this.tokenPrices[token.chainId][token.address] = {
      valuation,
      updatedAt: new Date()
    }
    return valuation
  }
}

export default new TokenValuationStore()
