<script setup lang="ts">
import { ButtonType } from '@/@types/ButtonType'
import type { GenericComponentSize } from '@/@types/GenericComponentSize'
import type { ButtonVariant } from '@/@types/ButtonVariant'
import type { ButtonColor } from '@/@types/ButtonColor'
import ButtonSizes from '@/ui-config/button-sizes'
import IconButtonSizes from '@/ui-config/icon-button-sizes'
import ButtonColors from '@/ui-config/button-colors/index'
import { DefineComponent } from 'vue'

withDefaults(
  defineProps<{
    type?: ButtonType,
    size?: GenericComponentSize,
    icon?: boolean,
    color?: ButtonColor,
    variant?: ButtonVariant,
    disabled?: boolean,
    loading?: boolean,
    as?: string | DefineComponent
  }>(),
  {
    type: 'button',
    size: 'md',
    color: 'brand',
    variant: 'gradient',
    disabled: false,
    as: 'button',
    icon: false,
    loading: false
  }
)

const spinnerSizes = {
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
  xl: 'h-6 w-6'
}
</script>

<template>
  <component
    :is="as"
    :type="type"
    v-bind="$attrs"
    :disabled="disabled"
    class="pp-btn"
    :class="[icon ? IconButtonSizes[size] : ButtonSizes[size], ButtonColors[color][variant]]"
  >
    <pp-spinner v-if="loading" :class="spinnerSizes[size]"></pp-spinner>
    <slot v-else>Button</slot>
  </component>
</template>
