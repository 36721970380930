import images from '@/assets/images/tokens'
import addresses from '@/app-config/addresses/aurora/tokens'

export default [
  {
    name: 'au Aurora',
    symbol: 'auAURORA',
    address: addresses.AU_AURORA,
    img: images.auaurora,
    decimals: 8,
    ignoreApproval: ['all']
  },
  {
    name: 'au Wrapped NEAR',
    symbol: 'auWNEAR',
    address: addresses.AU_WNEAR,
    img: images.auwnear,
    decimals: 8,
    ignoreApproval: ['all']
  },
  {
    name: 'au USDC',
    symbol: 'auUSDC',
    address: addresses.AU_USDC,
    img: images.auusdc,
    decimals: 8,
    ignoreApproval: ['all']
  },
  {
    name: 'au USDT',
    symbol: 'auUSDT',
    address: addresses.AU_USDT,
    img: images.auusdt,
    decimals: 8,
    ignoreApproval: ['all']
  },
  // {
  //   name: 'au Dai',
  //   symbol: 'auDAI',
  //   address: addresses.AU_DAI,
  //   img: images.audai,
  //   decimals: 8,
  //   ignoreApproval: ['all']
  // },
  {
    name: 'au Ether',
    symbol: 'auETH',
    address: addresses.AU_ETH,
    img: images.aueth,
    decimals: 8,
    ignoreApproval: ['all']
  },
  {
    name: 'au Wrapped BTC',
    symbol: 'auWBTC',
    address: addresses.AU_WBTC,
    img: images.auwbtc,
    decimals: 8,
    ignoreApproval: ['all']
  },
  {
    name: 'au Ply',
    symbol: 'auPLY',
    address: addresses.AU_PLY,
    img: images.auply,
    decimals: 8,
    ignoreApproval: ['all']
  }
]
