<script setup lang="ts">
import type { InputElementEvent } from '@/@types/InputElementEvent'
import type { HTMLInputMode } from '@/@types/HTMLInputMode'
import { ref, watch, Ref } from 'vue'

// @ts-ignore
const numberInputField = ref<HTMLInputElement>() as Ref<HTMLInputElement>

const props = withDefaults(
  defineProps<{
    modelValue: string
    maxDecimal?: number
    disabled?: boolean
    placeholder?: string
    autocomplete?: 'on' | 'off'
    readonly?: boolean
    inputmode?: HTMLInputMode
    type?: string
  }>(),
  {
    placeholder: '',
    disabled: false,
    maxDecimal: 99,
    autocomplete: 'off',
    readonly: false,
    type: 'text',
    inputmode: 'tel'
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
}>()

const formatValue = (value: string) => {
  // modified from https://stackoverflow.com/posts/70342439/revisions
  const [integer, fractional] = value.split('.')
  const trimmedInteger = integer.replace(/\b0+/g, '')
  const preferredInteger = integer.length > 0
    ? trimmedInteger.length > 0 ? trimmedInteger : '0'
    : ''

  const trimmedValue = [preferredInteger, fractional].filter(data => typeof data === 'string').join('.')
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(new RegExp(`([0-9]{0,99}(.[0-9]{0,${props.maxDecimal}})?).*`, 'g'), '$1')

  return trimmedValue
}

const handleInput = (inputValue: string) => {
  const value = formatValue(inputValue)
  numberInputField.value.value = value
  emit('update:modelValue', value)
}

watch(() => props.maxDecimal, () => {
  handleInput(props.modelValue)
})
</script>

<template>
  <input
    class="pp-input-field"
    @wheel.prevent
    step="any"
    ref="numberInputField"
    :value="modelValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :readonly="readonly"
    :type="type"
    :inputmode="inputmode"
    @input="handleInput(($event as InputElementEvent).target.value)"
  />
</template>
