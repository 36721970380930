<script setup lang="ts">
import DesktopNavbar from './DesktopNavbar.vue'
import MobileNavbar from './MobileNavbar.vue'
import { INTERNAL_LINKS, EXTERNAL_LINKS } from '@/ui-config/links'

const internalLinks = INTERNAL_LINKS
const externalLinks = EXTERNAL_LINKS
</script>

<template>
  <DesktopNavbar
    v-bind="$attrs"
    :internal-links="internalLinks"
    :external-links="externalLinks"
    class="hidden md:flex"
  />

  <MobileNavbar
    v-bind="$attrs"
    :internal-links="internalLinks"
    :external-links="externalLinks"
    class="flex md:hidden"
  />
</template>
